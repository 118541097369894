import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import FirstInactiveKickHandlerModule
  from "@/includes/logic/Modules/modules/modules/FirstInactiveKickHandlerModule/FirstInactiveKickHandlerModule";

export default class FirstInactiveKickHandlerModuleBuilder extends ModuleBuilder {
  model!: FirstInactiveKickHandlerModule;

  view: ModuleBuilder['view'] = () => import('./FirstInactiveKickHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'delete',
  }
}

