import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import ConfigCondition from "../../ConfigCondition";

export default class ResolveFromCondition extends ConfigCondition {
  type!:ModuleConditionTypeEnum

  work_before = false

  value = 0
}
