import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import RestrictCommentHandlerModule from "./RestrictCommentHandlerModule";

export default class RestrictCommentHandlerModuleBuilder extends ModuleBuilder {
  model!: RestrictCommentHandlerModule;

  view: ModuleBuilder['view'] = () => import('./RestrictCommentHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'comments_disabled',
  }
}

