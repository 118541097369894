import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import EntertainmentHandlerModule
  from "@/includes/logic/Modules/modules/modules/EntertainmentHandlerModule/EntertainmentHandlerModule";

export default class EntertainmentHandlerModuleBuilder extends ModuleBuilder {
  model!: EntertainmentHandlerModule;

  view: ModuleBuilder['view'] = () => import('./EntertainmentHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'videogame_asset',
  }

  aliases = [ 'игры' ]
}

