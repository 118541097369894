import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";

import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import ModerateCommandHandlerModule from "@/includes/logic/Modules/modules/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModule";

export default class ModerateCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: ModerateCommandHandlerModule;

  view: ModuleBuilder['view'] = () => import('./ModerateCommandHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'code',
  }
}

