import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import CommandLimitHandlerModule
  from "@/includes/logic/Modules/modules/modules/CommandLimitHandlerModule/CommandLimitHandlerModule";

export default class CommandLimitHandlerModuleBuilder extends ModuleBuilder {
  model!: CommandLimitHandlerModule;

  view: ModuleBuilder['view'] = () => import('./CommandLimitHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'code_off',
  }
}

