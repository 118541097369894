import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import AutocorrectionHandlerModule from "./AutocorrectionHandlerModule";

export default class AutocorrectionHandlerModuleBuilder extends ModuleBuilder {
  model!: AutocorrectionHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.Extra
  view = null

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'language',
  }
}

