import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import ChatTopCommandHandlerModule from "./ChatTopCommandHandlerModule";

export default class ChatTopCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: ChatTopCommandHandlerModule;

  view: ModuleBuilder['view'] = () => import('./ChatTopCommandHandlerModuleView.vue')

  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'star_half',
  }

  aliases = [ 'Top' ]
}
