















































































import {
  FormValidationGreetingProtection
} from "@/includes/logic/Modules/modules/modules/NewUsersHandlerModule/protection";
import { InputSetups } from "@/mixins/input-setups";
import AddAdmin from "@/components/AddAdmin/AddAdmin.vue";
import { AddAdminType } from "@/includes/types/AddAdmin/Enums";
import BaseProtectionTypeFields from "./BaseProtectionTypeFields.vue";

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  data() {
    return {
      AddAdminType
    }
  },
  components: {
    TextInput,
    BaseProtectionTypeFields,
    SelectInput,
    SwitchInput,
    ConfigField,
    AddAdmin,
    NestedContent
  }
})
export default class FormValidationGreetingProtectionView extends Mixins(InputSetups) {
  @VModel() model!: FormValidationGreetingProtection

  @Prop({ type: Boolean, default: false }) addUsersDisabled!: boolean

  @Prop({ type: String }) moduleGuid!: string

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  goToForms(option?: SelectOption): void {
    this.$router.push({
      name: 'questionnaire_form',
      params: {
        actionType: 'new',
      },
      query: {
        initName: option?.label
      }
    })
  }
}
