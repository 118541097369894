import './includes/polyfills/ResizeObserver'

import 'reflect-metadata';

import "core-js/stable";
import "regenerator-runtime/runtime";

import "@/assets/css/main.css";
import "./assets/scss/main.scss";
import 'ant-design-vue/dist/antd.less'

import 'piramis-base-components/src/assets/scss/main.scss'

import Vue, { PluginFunction } from "vue";
import { DynamicReactiveRefs, ReactiveRefs } from "vue-reactive-refs";
import VueScreen from "vue-screen";
import App from "./App.vue";

import i18n from "./i18n/i18n";
import router from "./router/router";
import store from "./store/store";
import isMobile from "@/assets/utils/isMobile";

import RouteHelpers from 'piramis-base-components/src/plugins/RouteHelpers/index'
import AdminTemplate from 'piramis-base-components/src/plugins/AdminTemplate'
import { AdminTemplateOptions, Layout } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Config";
import { MenuItemType } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";
import { ProductNames } from "piramis-base-components/src/plugins/AdminTemplate/components/OurProducts/types";
import { hasMatchedRouteParams } from "piramis-base-components/src/components/SelectEntityWizard/includes/helpers";
import { tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers';
import TokenId from "piramis-base-components/src/shared/utils/TokenId";

import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper";
import { Board } from "@/views/posts-planner/posts.types";
import {
  actionTypeBreadcrumb,
  baseBoardBreadcrumbs,
  baseChatBreadcrumbs,
  baseNetworkBreadcrumbs
} from "@/includes/AppConfigBreadcrumbsHelper";
import state from "@/store/state";
import { BaseChatInfo } from "@/includes/types/Chat/types";
import { deleteRecurringInvoice, getRecurringInvoicesReq } from './includes/logic/RecurringInvoices/logic';
import { getNetworkConfig } from "@/includes/logic/Networks/logic";
import { getNetworkTitle } from "@/includes/logic/Networks/utils";
import { ADVANCED_TAG, BRAND_TAG, DEFAULT_BOARD_TOKEN, ULTIMATE_TAG } from './includes/constants';
import './directives'

import Antd from 'ant-design-vue'
import { isEmpty } from "lodash";
import { Guid } from "guid-typescript";

Vue.use(RouteHelpers as unknown as PluginFunction<any>, { router })

Vue.use(Antd)

Vue.use(VueScreen, {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
});

Vue.use(DynamicReactiveRefs);
Vue.use(ReactiveRefs);

const adminTemplateOptions: AdminTemplateOptions = {
  config: {
    footer: {
      version: (store.state as any).metaModule.PACKAGE_VERSION,
      ourProducts: {
        exclude: [
          ProductNames.CHATKEEPERBOT,
        ],
        settings: {
          [ProductNames.PRESSCODEBOT]: {
            link: `${ tgLoginLink('Presscodebot') }?start=cabinet_chatkeeper`
          }
        }
      }
    },
    meta: {
      projectName: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? 'Infibot.app' : 'ChatKeeper.app',
      apiUrl: 'api.chatkeeper.info/cabinet',
      projectDomain: 'https://chatkeeper.app',
      sidebarLogo: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? require('./assets/images/infibot/logo/logo.png') : require('./assets/images/logo/logo.png'),
      loginPageLogo: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? require('./assets/images/infibot/logo/logo-full.png') : require('./assets/images/logo/text-logo-dark.png'),
      tgResolvers: {
        supportBotDomain: 'ChatKeeperBotSupport',
        promotionBotDomain: 'ChatKeeperPromotionBot',
        mainBotDomain: 'ChatKeeperBot',
      },
      login: {
        // botAuthLink: 'https://t.me/ChatKeeperBot?start=cabinetlink',
        botAuthLink: 'https://t.me/ChatKeeperBot?start=cabinetlinkbeta',
        loginWidget: {
          botLogin: 'chatkeeperbot',
          tgWidgetAuthLink: 'u',
        }
      },
      languageCodes: [ 'ru', 'en' ],
      isSaveButtonVisible: false,
      paymentEntity: {
        target_type: (ctx) => {
          return {
            target_id: {
              chat_id: (store.state.chatState.chat as any)?.chat_id,
            },
            target_type: 'chat'
          }
        }
      },
    },
    profile: {
      advertiseButton: () => 'https://cabinet.chatkeeper.info/web/tgresolve/en/ChatKeeperPromotionBot%3Fstart%3Dloyalty_parnters',
      recurringInvoices: {
        getRecurringInvoicesReq: () => {
          return getRecurringInvoicesReq()
              .then(res => {
                if (res) {
                  return res
                }

                return []
              })
        },
        deleteRecurringInvoiceReq: (invoice, vm) => {
          return deleteRecurringInvoice(invoice, vm)
              .then(res => {
                if (res) {
                  return res
                }
              })
        },
      }
    },
    loyaltySystem: {
      partnerBlock: {
        partnerLink: (localeCode) => 'https://cabinet.chatkeeper.info/web/tgresolve/en/ChatKeeperPromotionBot%3Fstart%3Dloyalty_parnters'
      },
      companiesBlock: {
        termsOwnerLink: (localeCode) => 'https://cabinet.chatkeeper.info/web/tgresolve/en/ChatKeeperPromotionBot%3Fstart%3Dloyalty_parnters'
      }
    },
    companyRegistration: {
      powerOfAttorney: (localeCode): string => 'https://chatkeeper.app/wp-content/uploads/2022/08/power_of_attorney_ur_aug_2022.doc',
      howToRegisterUr: (localeCode): string => 'https://chatkeeper.app/wp-content/uploads/2022/08/how_to_register_ur-4.docx',
    },
    search: {
      entities: async (ctx) => {
        const chatsList = store.state.chatState.chatsInfo

        if (!chatsList) {
          await store.dispatch("requestChatsInfo")
        }

        return (store.getters.sortedVisibleChatsInfos as Array<BaseChatInfo>)
            .map((c) => ({ avatar: c.group_photo, title: c.group_title, id: c.chat_id }))
      },
      path: (ctx, router, selectedId) => {
        if (selectedId !== null) {
          const params = {
            [EntityTypes.CHAT_ID]: selectedId.toString()
          }

          if (!state.chatState.chat) {
            router.push({
              name: "Community_dashboard",
              params
            })
          } else {
            router.push({
              params
            })
          }
        }
      }
    },
    rightScreenButton: {
      link: (localeCode) => {
        if (localeCode === 'ru') return 'https://t.me/PressCodeSupportRu'
        else return 'https://t.me/PressCodeSupport'
      },
    },
    serviceData: {
      type: 'ServiceData',
      tariffs: {
        starter: {
          type: 'TariffInfo',
          img: require('./assets/images/payments/tariffs/chatkeeper/starter.png'),
          color: 'rgb(177,69,250)',
          id: 1,
        },
        advanced: {
          type: 'TariffInfo',
          img: require('./assets/images/payments/tariffs/chatkeeper/advanced.png'),
          color: 'rgb(177,69,250)',
          id: 2,
        },
        ultimate: {
          type: 'TariffInfo',
          img: require('./assets/images/payments/tariffs/chatkeeper/ultimate.png'),
          color: 'rgb(177,69,250)',
          id: 3,
        },
      },
    },
    topMenu: [
        {
        menuItem: {
          type: MenuItemType.Component,
          isVisible: [ true ],
          component: () => import('piramis-base-components/src/plugins/AdminTemplate/components/feedback/FeedbackContainer.vue')
        },
      },
    ],
    feedbackItems: [
      {
        menuItem: {
          type: MenuItemType.DropDownLink,
          isVisible: [ true ],
          name: 'menu_help_youtube_title',
          icon: 'icon-youtube',
          featherIcon: true,
          url: 'https://www.youtube.com/@user-gz2go8dj9o',
        },
      },
      {
        menuItem: {
          isVisible: [ true ],
          type: MenuItemType.DropDownLink,
          name: 'menu_help_tech_support_title',
          icon: 'icon-life-buoy',
          featherIcon: true,
          url: () => {
            const locale = localStorage && localStorage.getItem('locale')

            if (locale) {
              if (locale === 'en') {
                return 'https://t.me/ChatKeeperBot_EN'
              }
            }

            return 'https://t.me/ChatKeeperBotSupport'
          },
        },
      },
      {
        menuItem: {
          isVisible: [ true ],
          type: MenuItemType.DropDownLink,
          name: 'menu_help_instruction_title',
          icon: 'icon-book',
          featherIcon: true,
          url: () => 'https://docs.chatkeeper.info',
        },
      },
      {
        menuItem: {
          isVisible: [ true ],
          type: MenuItemType.Component,
          name: 'menu_regexp_title',
          icon: 'icon-flag',
          featherIcon: true,
          component: () => import('piramis-base-components/src/plugins/AdminTemplate/components/regexp-validator/RegexpValidator.vue')
        },
      },
    ],
    sidebarMenu: [
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworkDashboard',
              path: '/networks/:networkId/dashboard',
              component: () => import("@/views/networks/network-dashboard.vue"),
              meta: {
                breadcrumbs: (ctx) => baseNetworkBreadcrumbs(ctx.route),
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-zap',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworkTriggers',
              path: '/networks/:networkId/triggers',
              component: () => import("@/views/networks/network-triggers.vue"),
              meta: {
                breadcrumbs: (ctx) => baseNetworkBreadcrumbs(
                      ctx.route,
                      [ { label: i18n.t('networktriggers_page_title').toString() } ]
                  ),
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-zap',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworkTrigger',
              path: '/networks/:networkId/triggers/:triggerId?/:actionType',
              component: () => import("@/views/networks/network-trigger.vue"),
              meta: {
                breadcrumbs: (ctx) => baseNetworkBreadcrumbs(
                    ctx.route,
                    [ { label: i18n.t('networktriggers_page_title').toString(), routeName: 'NetworkTriggers' },
                      ...ctx.route.params.triggerId ? [ { label: TokenId.getId(ctx.route.params.triggerId.toString()) } ] : [],
                      { label: actionTypeBreadcrumb(ctx) }
                    ]
                ),
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworksAdd',
              path: '/networks/add',
              component: () => import("@/views/networks/networks-add.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworkModulesCatalog',
              path: '/networks/:networkId/modules-catalog',
              component: () => import("@/views/modules/modules-catalog.vue"),
              meta: {
                breadcrumbs: (ctx) => baseNetworkBreadcrumbs(
                    ctx.route,
                    [ { label: i18n.t('networkmodulescatalog_page_title').toString() } ]
                )
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworkActiveModules',
              path: '/networks/:networkId/active-modules',
              component: () => import("@/views/modules/active-modules.vue"),
              meta: {
                breadcrumbs: (ctx) => baseNetworkBreadcrumbs(
                    ctx.route,
                    [ { label: i18n.t('networkactivemodules_page_title').toString() } ]
                )
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-message-circle',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworkCurrentModule',
              path: `/networks/:networkId/module/:moduleGuidOrType`,
              component: () => import("@/includes/logic/Modules/components/CurrentModule.vue"),
              meta: {
                breadcrumbs: (ctx) => baseNetworkBreadcrumbs(ctx.route,
                  [ { label: i18n.t('menu_activemodules_title').toString() },
                          ModuleManagerState && ModuleManagerState.currentModule
                            ? { label: i18n.t(ModuleManagerState.currentModule.model.titleKey).toString() }
                            : {} ]
                )
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-message-circle',
          isVisible: [ true ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'chats',
              path: '/',
              component: () => import("@/views/chats.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-eye-off',
          isVisible: [ (ctx) => store.getters.hasHiddenChats && store.getters.isChatSet || !!ctx && [ 'chats', 'hidden_chats' ].includes(ctx.route.name!.toString()) ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'hidden_chats',
              path: '/hidden',
              component: () => import("@/views/hidden-chats.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-database',
          isVisible: [ () => !!store.state.chatState.chatsInfo?.chats && store.state.chatState.chatsInfo.chats.length > 1 ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'NetworksList',
              path: '/networks',
              component: () => import("@/views/networks/networks-list.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.DynamicHeader,
          isVisible: [ (ctx) => !!ctx &&!!ctx.route.params.networkId ],
          header: (ctx) => {
            const network = store.state.networksState.currentNetwork

            return network ? getNetworkTitle(network) : ''
          },
          icon: 'icon-database',
          featherIcon: true,
          class: [],
          to: { name: 'NetworkDashboard' }
        },
      },
      {
        menuItem: {
          isVisible: [ (ctx) => !!ctx && !!ctx.route.params.networkId ],
          type: MenuItemType.Divider
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ () => store.getters.hasAtLeastOneAdvanced && !store.getters.isChatSet ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'boards',
              path: '/boards',
              component: () => import("@/views/posts-planner/views/boards.vue"),
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-calendar',
          isVisible: [
            (ctx) => !!ctx && !!ctx.route.params[EntityTypes.BOT_ID],
            () => store.getters.hasAtLeastOneAdvanced
          ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'posts_planner',
              path: `/board/:${ [ EntityTypes.BOT_ID ] }/posts-planner`,
              component: () => import("@/views/posts-planner/views/posts-planner.vue"),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseBoardBreadcrumbs(ctx.route),
                  { label: i18n.t('menu_posts_planner_title') },
                ]
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layers',
          isVisible: [ (ctx) => !!ctx && !!ctx.route.params[EntityTypes.BOT_ID] ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'board_styles',
              path: `/board/:${ EntityTypes.BOT_ID }/styles`,
              component: () => import("@/views/posts-planner/views/board-styles-list.vue"),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseBoardBreadcrumbs(ctx.route),
                  { label: i18n.t('menu_board_styles_title') },
                ]
              }
            },
          },
          class: [],
          tag: (): string => {
            const boardId = router.app.$route.params[EntityTypes.BOT_ID]

            const currentBoard: Board | undefined = (store.state.postsPlanner.boards ?? []).find((board:Board) => board.board.token_id.toString() === boardId)

            if (currentBoard?.board?.token_code === DEFAULT_BOARD_TOKEN) {
              return BRAND_TAG[0].text
            }

            return ''
          },
          tagColor: (): string => BRAND_TAG[0].color
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layers',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'style',
              path: `/board/:${ EntityTypes.BOT_ID }/styles/:actionType`,
              component: () => import("@/views/posts-planner/views/style-page.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseBoardBreadcrumbs(ctx.route),
                    { label: i18n.t('menu_board_styles_title') },
                    ...ctx.route.query.styleId ? [ { label: ctx.route.query.styleId.toString().split('.')[0] } ] : [],
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
            },
          },
          class: [],
          tag: (): string => {
            const boardId = router.app.$route.params[EntityTypes.BOT_ID]
            const currentBoard: Board | undefined = (store.state.postsPlanner.boards ?? []).find((board:Board) => board.board.token_id.toString() === boardId)

            if (currentBoard?.board?.token_code === DEFAULT_BOARD_TOKEN) {
              return BRAND_TAG[0].text
            }

            return ''
          },
          tagColor: (): string => BRAND_TAG[0].color
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-layout',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'post',
              path: `/board/:${ EntityTypes.BOT_ID }/post/:actionType`,
              component: () => import("@/views/posts-planner/views/post.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseBoardBreadcrumbs(ctx.route),
                    { label: i18n.t('menu_posts_planner_title') },
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-file-text',
          isVisible: [ (ctx) => !!ctx && !!ctx.route.params[EntityTypes.BOT_ID] ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'drafts',
              path: `/board/:${ EntityTypes.BOT_ID }/drafts/`,
              component: () => import("@/views/posts-planner/views/drafts-list.vue"),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseBoardBreadcrumbs(ctx.route),
                  { label: i18n.t('menu_drafts_title') },
                ]
              }
            },
          },
          class: [],
          tag: (): string => {
            const boardId = router.app.$route.params[EntityTypes.BOT_ID]
            const currentBoard: Board | undefined = (store.state.postsPlanner.boards ?? []).find((board: Board) => board.board.token_id.toString() === boardId)

            if (currentBoard?.board?.token_code === DEFAULT_BOARD_TOKEN) {
              return BRAND_TAG[0].text
            }

            return ''
          },
          tagColor: () => BRAND_TAG[0].color,
        },
      },
      {
        menuItem: {
          type: MenuItemType.DynamicHeader,
          isVisible: [
            (ctx) => store.getters.isChatSet ,
          ],
          header: (ctx) => ( store.state.chatState.chat as any).group_title,
          avatar: (ctx) => ( store.state.chatState.chat as any).group_photo,
          class: [],
          to: { name: 'Community_dashboard' }
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [
            (ctx) => store.getters.isChatSet,
          ],
          featherIcon: true,
          icon: 'icon-shopping-bag',
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'buy',
              path: `/chat/:${ EntityTypes.CHAT_ID }/buy/`,
              component: () => import('@/views/buy-chat-license.vue'),
              meta: {
                breadcrumbs: (ctx) => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('nav_buy_title') },
                ]
              }
            },
          },
          class: [ 'vu-nav-license' ],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Divider
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-monitor',
          isVisible: [
            (ctx) => store.getters.isChatSet
          ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'Community_dashboard',
              path: `/chat/:${ EntityTypes.CHAT_ID }/`,
              component: () => import("@/views/community-dashboard.vue"),
              meta: {
                breadcrumbs: () => {
                  return [
                    ...baseChatBreadcrumbs(),
                    { label: i18n.t('menu_dashboard_title') }
                  ]
                }
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-life-buoy',
          isVisible: [ () => store.getters.isChatSet ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'ActiveModules',
              path: `/chat/:${ EntityTypes.CHAT_ID }/active-modules`,
              component: () => import("@/views/modules/active-modules.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_activemodules_title') },
                ]
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-message-circle',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'ModulesCatalog',
              path: `/chat/:${ EntityTypes.CHAT_ID }/modules-catalog/:tab?`,
              component: () => import("@/views/modules/modules-catalog.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_activemodules_title'), routeName: 'ActiveModules' },
                  { label: i18n.t('modulescatalog_page_title') },
                ]
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          featherIcon: true,
          icon: 'icon-message-circle',
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'CurrentModule',
              path: `/chat/:${ EntityTypes.CHAT_ID }/module/:moduleGuidOrType`,
              component: () => import("@/includes/logic/Modules/components/CurrentModule.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_activemodules_title') },
                  ModuleManagerState && ModuleManagerState.currentModule
                      ? { label: i18n.t(ModuleManagerState.currentModule.model.titleKey) }
                      : {}
                ]
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-globe',
          type: MenuItemType.Route,
          isVisible: [
            false
          ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'migrate_chat_to_modules',
              path: `/chat/:${ EntityTypes.CHAT_ID }/migrate`,
              component: () => import("@/views/chat/migrate-chat-to-modules-landing.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('modules_migrate_preview_card_title') },
                ]
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.DropDown,
          guid: Guid.create().toString(),
          isVisible: [
            (ctx) => store.getters.isChatSet
          ],
          featherIcon: true,
          icon: 'icon-settings',
          name: 'Chat_settings',
          submenu: [
            {
              menuItem: {
                featherIcon: true,
                icon: 'icon-globe',
                type: MenuItemType.Route,
                isVisible: [
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'chat_common_settings',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/settings/:tab?`,
                    component: () => import("@/views/chat-common-settings.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_chat_common_settings_title') },
                      ]
                    }
                  },
                },
                class: [],
              },
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                featherIcon: true,
                icon: 'icon-user-check',
                isVisible: [
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'users_rights',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/users-rights`,
                    component: () => import("@/views/chat/users-rights.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_users_rights_title') }
                      ]
                    }
                  },
                },
                class: [],
              },
            },
            {
              menuItem: {
                featherIcon: true,
                icon: 'icon-zap',
                type: MenuItemType.Route,
                isVisible: [
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'chat_triggers',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/triggers`,
                    component: () => import("@/views/chat/triggers.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_chat_triggers_title') },
                      ]
                    }
                  },
                },
                class: [],
              },
            },
            {
              menuItem: {
                featherIcon: true,
                icon: 'icon-clipboard',
                type: MenuItemType.Route,
                isVisible: [ ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'questionnaire',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/questionnaire/`,
                    component: () => import("@/views/chat/questionnaire/questionnaire.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_questionnaire_title') }
                      ]
                    }
                  },
                },
                class: [],
                tag: () => {
                  if (!(store.state.chatState.chat as any)?.limits?.options.includes('forms')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('forms'))).toString()
                  }
                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('forms')))).color
                }
              }
            },
            {
              menuItem: {
                featherIcon: true,
                icon: 'icon-star',
                type: MenuItemType.Route,
                isVisible: [ ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'Achievements',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/achievements/`,
                    component: () => import("@/views/achievements.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_achievements_title') }
                      ]
                    }
                  },
                },
                tag: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? '' : ULTIMATE_TAG[0].text,
                tagColor: () => ULTIMATE_TAG[0].color
              }
            },
            {
              menuItem: {
                featherIcon: true,
                icon: 'icon-tag',
                type: MenuItemType.Route,
                isVisible: [
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'Branding',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/branding`,
                    component: () => import("@/views/chat/branding.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('nav_bar_settings_tab_title') },
                        { label: i18n.t('menu_branding_title') },
                      ]
                    }
                  },
                },
                class: [],
                tag: () => {
                  if (!(store.state.chatState.chat as any)?.limits?.options.includes('branding')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('branding'))).toString()
                  }

                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('branding')))).color
                }
              },
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                    () => store.getters.isChatSet && store.state.chatState.chat!.version >= 6000
                ],
                icon: 'icon-grid',
                featherIcon: true,
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'credentials',
                    path: `/chat/:${ EntityTypes.CHAT_ID }/credentials/`,
                    component: () => import("@/views/chat-credentials.vue"),
                    meta: {
                      breadcrumbs: (ctx) => {
                        return [
                          ...baseChatBreadcrumbs(),
                          { label: i18n.t('menu_credentials_title') },
                        ]
                      }
                    }
                  },
                },
                class: [],
              }
            },
          ]
        }
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-zap',
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'chat_trigger',
              path: `/chat/:${ EntityTypes.CHAT_ID }/triggers/:triggerId?/:actionType`,
              component: () => import("@/views/chat/trigger.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseChatBreadcrumbs(),
                    { label: i18n.t('menu_chat_triggers_title'), routeName: 'chat_triggers' },
                    ...ctx.route.params.triggerId ? [ { label: TokenId.getId(ctx.route.params.triggerId.toString()) } ] : [],
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
            },
          },
          class: [],
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'Form',
              path: "/form/:key/",
              component: () => import("@/views/form/form.vue"),
              meta: {
                requiresAuth: false
              }
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          featherIcon: true,
          icon: 'icon-calendar',
          type: MenuItemType.Route,
          isVisible: [ (ctx) => store.getters.isChatSet ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'posts_planner',
              path: `/board/${ EntityTypes.BOT_ID }/posts-planner`,
              params: () => {
                return {
                  [EntityTypes.BOT_ID]: ( store.state.chatState.chat as any).post_board.token_id
                }
              },
              component: () => import("@/views/posts-planner/views/posts-planner.vue"),
            },
          },
          tag: () => {
            if (!store.getters.isChatAtLeastAdvanced || !store.getters.isChatLicenseExists) {
              return 'Advanced'
            }
            return ''
          },
          tagColor: () => {
            return store.state.themeConfig.getTariff('Advanced').color
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'questionnaire_form',
              path: `/chat/:${ EntityTypes.CHAT_ID }/questionnaire/form/:actionType/`,
              component: () => import("@/views/chat/questionnaire/questionnaire-form.vue"),
              meta: {
                breadcrumbs: (ctx) => {
                  return [
                    ...baseChatBreadcrumbs(),
                    { label: i18n.t('menu_questionnaire_title'), routeName: 'questionnaire' },
                    ...ctx.route.query.formGuid && ctx.route.params.actionType !== 'copy' ? [ { label: ctx.route.query.formGuid.toString().split('.')[0] } ] : [],
                    { label: actionTypeBreadcrumb(ctx) }
                  ]
                }
              }
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.DropDown,
          featherIcon: true,
          icon: 'icon-link',
          guid: Guid.create().toString(),
          isVisible: [ (ctx) => store.getters.isChatSet ],
          name: 'tab_invite_links',
          submenu: [
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => store.getters.isChatSet ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'invite_links_list',
                    path: `chat/:${ EntityTypes.CHAT_ID }/link-list`,
                    component: () => import("@/views/chat/invite-links/link-list.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_invite_links_list_title') }
                      ]
                    }
                  },
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => store.getters.isChatSet ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'invite_links_statistics',
                    path: `chat/:${ EntityTypes.CHAT_ID }/link-statistics`,
                    component: () => import("@/views/chat/invite-links/link-statistics.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_invite_links_list_title'), routeName: 'invite_links_list' },
                        { label: i18n.t('menu_invite_links_statistics_title') }
                      ]
                    }
                  },
                },
                class: [],
              }
            },
          ]
        },
      },
      {
        menuItem: {
          type: MenuItemType.DropDown,
          guid: Guid.create().toString(),
          featherIcon: true,
          icon: 'icon-bar-chart',
          isVisible: [ (ctx) => store.getters.isChatSet ],
          name: 'tab_chat_statistics',
          submenu: [
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => store.getters.isChatSet ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics`,
                    component: () => import("@/views/chat/statistics/statistics.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_title'), },
                      ]
                    }
                  },
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => store.getters.isChatSet ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics_users',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/users`,
                    component: () => import("@/views/chat/statistics/statistics-users.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_users_title'), },
                      ]
                    }
                  },
                },
                tag: () => {
                  if (!(store.state.chatState.chat as any)?.limits?.options.includes('stat_users')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_users'))).toString()
                  }

                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_users')))).color
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => store.getters.isChatSet ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics_users_analysis',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/users-analysis`,
                    component: () => import("@/views/chat/statistics/statistics-users-analysis-new.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_users_analysis_title'), },
                      ]
                    }
                  },
                },
                tag: () => {
                  if (!(store.state.chatState.chat as any)?.limits?.options.includes('statistic_users_analysis')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('statistic_users_analysis'))).toString()
                  }

                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('statistic_users_analysis')))).color
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => store.getters.isChatSet ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistics_referrals',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/referrals`,
                    component: () => import("@/views/chat/statistics/statistics-referrals.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistics_referrals_title'), },
                      ]
                    }
                    },
                },
                class: [],
                tag: () => {
                  if (!(store.state.chatState.chat as any)?.limits?.options.includes('stat_referrals')) {
                    return i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_referrals'))).toString()
                  }
                  return ''
                },
                tagColor: () => {
                  return store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('stat_referrals')))).color
                }
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [ (ctx) => store.getters.isChatSet ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'reputation_chart',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/reputation`,
                    component: () => import("@/views/chat/reputation/reputation-chart.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_reputation_chart_title'), },
                      ]
                    }
                  },
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                    (ctx) => store.getters.isChatSet,
                    false
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistic_topics',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/topics`,
                    component: () => import("@/views/chat/statistics/statistics-topics.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistic_topics_title'), },
                      ]
                    }
                  },
                },
                class: [],
              }
            },
            {
              menuItem: {
                type: MenuItemType.Route,
                isVisible: [
                  (ctx) => store.getters.isChatSet,
                ],
                routeConfig: {
                  layout: Layout.Panel,
                  config: {
                    name: 'statistic_reactions',
                    path: `chat/:${ EntityTypes.CHAT_ID }/statistics/reactions`,
                    component: () => import("@/views/chat/statistics/statistics-reactions.vue"),
                    meta: {
                      breadcrumbs: () => [
                        ...baseChatBreadcrumbs(),
                        { label: i18n.t('menu_tab_chat_statistics_title'), },
                        { label: i18n.t('menu_statistic_reactions_title'), },
                      ]
                    }
                  },
                },
                class: [],
                tag: (): string => {
                  if (!store.getters.isChatAtLeastAdvanced) {
                    return ADVANCED_TAG[0].text
                  }

                  return ''
                },
                tagColor: (): string => ADVANCED_TAG[0].color
              }
            },
          ]
        },
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          icon: 'icon-book',
          featherIcon: true,
          isVisible: [ (ctx) => store.getters.isChatSet ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'Journal_log',
              path: `chat/:${ EntityTypes.CHAT_ID }/journal/log`,
              component: () => import("@/views/chat/journal/journal-log.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_journal_log_title'), },
                ]
              }
            },
          },
          class: [ 'journal-menu-item' ],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Divider
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ (ctx) => store.getters.isChatSet ],
          featherIcon: true,
          icon: 'icon-more-horizontal',
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'extra',
              path: `chat/:${ EntityTypes.CHAT_ID }/extra`,
              component: () => import("@/views/chat/extra.vue"),
              meta: {
                breadcrumbs: () => [
                  ...baseChatBreadcrumbs(),
                  { label: i18n.t('menu_extra_title'), },
                ]
              }
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'Payment',
              path: "/payment/:type/:key/",
              component: () => import("@/views/payment/payment.vue"),
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'Choose_chat',
              path: "/buy-license/choose-chat/",
              component: () => import("@/views/buy-license/choose-chat.vue"),
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Fullscreen,
            config: {
              name: 'start_add_chat',
              path: "/add-chat/:key?",
              component: () => import("@/views/add-chat.vue"),
              beforeEnter: (to, from, next) => {
                next({ name: "chats" })
              }
            },
          },
          class: [],
        }
      },
      {
        menuItem: {
          type: MenuItemType.Route,
          isVisible: [ false ],
          routeConfig: {
            layout: Layout.Panel,
            config: {
              name: 'user_info',
              path: `/chat/:${ EntityTypes.CHAT_ID }/user-info/:userId`,
              component: () => import("@/views/chat/user-info.vue"),
            },
          },
          class: [],
        }
      },
    ],
    globalGuards: {
      afterEach: (to) => {
        const chatIdParam = to.params[EntityTypes.CHAT_ID]
        const botIdParam = to.params[EntityTypes.BOT_ID]
        const networkIdParam = to.params.networkId

        if (!chatIdParam) {
          store.commit("updateChat", null);
          store.commit("resetFrontendTriggers");
          store.commit('clearChatStateFields')

          document.title = `${ process.env.VUE_APP_TARGET_PROJECT }.app`
        }

        if (store.state.chatState.chat) {
          const chat = store.state.chatState.chat

          document.title = `${ chat.group_title } (${ chat.chat_id })`
        }

        if (!botIdParam && store.state.postsPlanner.currentBoard) {
          store.commit('clear_current_board')
        }

        if (!networkIdParam && store.state.networksState.currentNetwork) {
          store.commit('CLEAR_CURRENT_NETWORK')
          store.commit('RESET_NETWORK_TRIGGERS')
        }

        if (to.name === 'boards' || botIdParam || !chatIdParam) {
          setTimeout(() => {
            Vue.prototype.$baseTemplate.saveButton.hide()
          }, 0)
        }
      },
      beforeEach: async (to, from, next) => {
        const chatData = store.state.chatState.chat;
        const chatIdParam = to.params[EntityTypes.CHAT_ID]
        const botIdParam = to.params[EntityTypes.BOT_ID]
        const networkIdParam = to.params.networkId

        if (chatIdParam) {
          if (Number.parseInt(chatIdParam) && !hasMatchedRouteParams(to)) {
            if (!chatData || chatData.chat_id !== parseInt(chatIdParam)) {
              store.commit("updateChat", null);
              store.commit("resetFrontendTriggers");
              store.commit('clearChatStateFields')

              await store.dispatch("requestChatConfig", { id: chatIdParam })

              if (ModuleManagerState && ModuleManagerState.currentModule && ModuleManagerState.currentModuleType) {
                ModuleManagerState.clearCurrentModule()
                ModuleManagerState.prepareModule(ModuleManagerState.currentModuleType, +chatIdParam)
              }
            }
          } else if (hasMatchedRouteParams(to)) {
            next()
          } else {
            next({ name: "chats" })
          }
        }

        if (networkIdParam) {
          if (!Number.isNaN(+networkIdParam)) {
            if (!store.state.networksState.currentNetwork) {

              const config = await getNetworkConfig(+networkIdParam)

              if (config) {
                await store.dispatch('setCurrentNetwork', config)
              } else {
                next({ name: "NetworksList" })
              }
            }
          } else {
            next({ name: "NetworksList" })
          }
        }

        if (to.path.includes('/boards') || botIdParam && !hasMatchedRouteParams(to)) {
          if (isEmpty(store.state.postsPlanner.boards)) {
            await store.dispatch("requestPostBoards")
          }

          if (botIdParam && !store.state.postsPlanner.currentBoard) {
            const board = await store.dispatch('setCurrentBoard', botIdParam)

            if (!board) {
              next({ name: 'boards' })
            }
          }
        }

        if (!networkIdParam && !botIdParam && !store.state.chatState.chatsInfo?.chats || to.name === 'chats') {
          await store.dispatch("requestChatsInfo", Vue)
        }

        next()
      },
    },
  },
  router: router,
  store: store,
}

Vue.use(AdminTemplate as unknown as PluginFunction<any>, adminTemplateOptions)

Vue.config.productionTip = false;

(window as any).mobilecheck = isMobile;

export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount("#app");
