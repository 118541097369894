import ConditionBuilder from "../../ConditionBuilder";
import ResolveFromRepCondition from "./ResolveFromRepCondition";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "../../../types";

class ResolveFromRepConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromRep

  icon = 'thumb_up';

  view:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionValue.vue');

  getModel(): ConfigCondition {
    return new ResolveFromRepCondition()
  }
}

export default new ResolveFromRepConditionBuilder()
