import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import ConfigCondition from "./ConfigCondition";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";
import { TagData } from "piramis-base-components/src/components/Tags/types";

import { Exclude } from "class-transformer";
import { snakeCase } from "lodash";

export default abstract class ConditionBuilder {
  abstract type: ModuleConditionTypeEnum

  @Exclude()
  abstract view: VueComponent
  @Exclude()
  abstract preview: VueComponent

  /**
   * material icon
   **/
  @Exclude()
  abstract icon: string

  @Exclude()
  get snakeCaseType() {
    return snakeCase(this.type)
  }

  @Exclude()
  getTitleKey(condition?: ConfigCondition) {
    return `module_condition_${ this.snakeCaseType }_title`
  }

  @Exclude()
  get helpMessageKey() {
    return `module_condition_${ this.snakeCaseType }_help_message`
  }
  isDisabled() {
    return false
  }

  tariffTag(): Array<TagData> | null {
    return null
  }

  isVisibleInList() {
    return true
  }

  abstract getModel(): ConfigCondition
}
