import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import MessageBindedChannelHandlerModule
  from "@/includes/logic/Modules/modules/modules/MessageBindedChannelHandlerModule/MessageBindedChannelHandlerModule";
import store from "@/store/store";
import { BRAND_TAG } from "@/includes/constants";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class MessageBindedChannelHandlerModuleBuilder extends ModuleBuilder {
  model!: MessageBindedChannelHandlerModule;

  view: ModuleBuilder['view'] = () => import('./MessageBindedChannelHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'flag',
  }

  tariffTags(): Array<TagData> | null {
    if (store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.getHaveBrand || !store.getters.getHaveLicense)) {
      return BRAND_TAG
    } else {
      return null
    }
  }

  isSaveable(): boolean {
    return this.model.params.binded_channel_id !== 0
  }
}

