import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import VideoNoteFilterHandlerModule from "./VideoNoteFilterHandlerModule";
import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import {
  BanDeleteMessageBuilder,
  EditDeleteMessageBuilder,
  FirstMessageDeleteMessageBuilder, NewLimitDeleteMessageBuilder,
  WarnDeleteMessageBuilder
} from "../../../PunishActions/FilterPunishActions";

export default class VideoNoteFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: VideoNoteFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "voice_chat",
    color: "rgba(var(--a-primary), 1)"
  };
  view =  () => import('@/includes/logic/Modules/modules/modules/VideoNoteFilterHandlerModule/VideoNoteHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', ]

setBuilderModel(moduleData: BaseModule) {
    super.setBuilderModel(moduleData);

    this.customPunishBuilders = {
      remove_edited_punish_action: [
        new EditDeleteMessageBuilder(this.model.filterType)
      ],
      ban_punish_action: [
        new BanDeleteMessageBuilder(this.model.filterType)
      ],
      first_message_punish_action: [
        new FirstMessageDeleteMessageBuilder(this.model.filterType)
      ],
      new_limit_punish_action: [
        new NewLimitDeleteMessageBuilder(this.model.filterType)
      ],
      warning_punish_action: [
        new WarnDeleteMessageBuilder(this.model.filterType)
      ]
    }
  }
}
