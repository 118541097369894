import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";
import { Type } from "class-transformer";

export default class AnyMessageFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.AnyMessageFilterHandlerModule;

  @Type(() => AnyMessageFilterHandlerModuleParams)
  params!: AnyMessageFilterHandlerModuleParams
}

class AnyMessageFilterHandlerModuleParams {
  any_message_handler_type!: MessageHandlerTypesEnum
}

export const enum MessageHandlerTypesEnum {
  "Any" = "Any",
  "TextCaption" = "TextCaption",
  "Text" = "Text",
}

