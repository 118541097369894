import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import BotGuardHandlerModule from "./BotGuardHandlerModule";

export default class BotGuardHandlerModuleBuilder extends ModuleBuilder {
  model!: BotGuardHandlerModule;

  view: ModuleBuilder['view'] = () => import('./BotGuardHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'smart_toy',
  }
}
