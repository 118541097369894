import { Config } from "driver.js";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import isMobile from "piramis-js-utils/lib/isMobile";
import store from "@/store/store";
import VueI18n from "vue-i18n";
import Vue from "vue";

export function getChatOnboardingConfig(i18n: VueI18n, vm: Vue): Config {
  return {
    disableActiveInteraction: true,
    showProgress: true,
    allowClose: false,
    nextBtnText: i18n.t('onboarding_next_button_title').toString(),
    prevBtnText: i18n.t('onboarding_prev_button_title').toString(),
    doneBtnText: i18n.t('onboarding_done_button_title').toString(),
    progressText: i18n.t('onboarding_progress_text').toString(),
    steps: [
      {
        element: '.dashboard-chat-info__cards',
        popover: {
          title: i18n.t('chat_onboarding_step_1_title').toString(),
          description: i18n.t('chat_onboarding_step_1_text').toString(),
          disableButtons: [ 'previous' ],
          showButtons: [ 'next' ],
          onPrevClick: () => {
          }
        }
      },
      {
        element: '.dashboard-chat-info__tariff',
        popover: {
          title: i18n.t('chat_onboarding_step_2_title').toString(),
          description: i18n.t('chat_onboarding_step_2_text').toString(),
        }
      },
      {
        element: '.dashboard-modules-card',
        popover: {
          title: i18n.t('chat_onboarding_step_3_title').toString(),
          description: i18n.t('chat_onboarding_step_3_text', { count: ModuleManagerState?.getActiveModules()?.length ?? 0 }).toString(),
        }
      },
      {
        element: '.dashboard-modules-card__setting-action',
        popover: {
          title: i18n.t('chat_onboarding_step_4_title').toString(),
          description: i18n.t('chat_onboarding_step_4_text').toString(),
          onNextClick: (_, __, opts) => {
            vm.$router.push({
              name: 'ActiveModules',
            })
                .then(() => {
                  opts.driver.moveNext()
                })
          }
        }
      },
      {
        element: '.NewUsersHandlerModule',
        popover: {
          title: i18n.t('chat_onboarding_step_5_title').toString(),
          description: i18n.t('chat_onboarding_step_5_text').toString(),
          onPrevClick: (_, __, opts) => {
            vm.$router.push({
              name: 'Community_dashboard',
            })
                .then(() => {
                  opts.driver.movePrevious()
                })
          }
        },
      },
      {
        element: '.active-modules__btn-add',
        popover: {
          title: i18n.t('chat_onboarding_step_6_title').toString(),
          description: i18n.t('chat_onboarding_step_6_text').toString(),
        },
      },
      {
        element: '.NewUsersHandlerModule',
        popover: {
          title: i18n.t('chat_onboarding_step_7_title').toString(),
          description: i18n.t('chat_onboarding_step_7_text').toString(),
          onNextClick: (_, __, opts) => {
            vm.$router.push({
              name: 'CurrentModule',
              params: {
                moduleGuidOrType: ModuleTypesEnum.NewUsersHandlerModule
              },
            })
                .then(() => {
                  opts.driver.moveNext()
                })
          }
        },
      },
      {
        element: '.page-header-anchor-top-position',
        popover: {
          title: i18n.t('chat_onboarding_step_8_title').toString(),
          description: i18n.t('chat_onboarding_step_8_text').toString(),
          onPrevClick: (_, __, opts) => {
            vm.$router.push({
              name: 'ActiveModules',
            })
                .then(() => {
                  opts.driver.movePrevious()
                })
          }
        },
      },
      {
        element: '.new-users-handler-module-view__settings',
        popover: {
          title: i18n.t('chat_onboarding_step_9_title').toString(),
          description: i18n.t('chat_onboarding_step_9_text').toString(),
        },
      },
      {
        element: '.new-users-handler-module-view__protection-type',
        popover: {
          title: i18n.t('chat_onboarding_step_10_title').toString(),
          description: i18n.t('chat_onboarding_step_10_text').toString(),
        },
      },
      {
        element: '.new-users-handler-module-view__btn-save',
        popover: {
          title: i18n.t('chat_onboarding_step_11_title').toString(),
          description: i18n.t('chat_onboarding_step_11_text').toString(),
          onNextClick: (_, __, opts) => {
            if (isMobile()) {
              vm.$baseTemplate.drawer.toggleVisible()
            }

            vm.$nextTick(() => {
              opts.driver.moveNext()
            })
          }
        },
      },
      {
        element: () => {
          if (isMobile()) {
            return document.querySelector('.ant-drawer-wrapper-body .journal-menu-item')!
          }

          return document.querySelector('.journal-menu-item')!
        },
        popover: {
          title: i18n.t('chat_onboarding_step_12_title').toString(),
          description: i18n.t('chat_onboarding_step_12_text').toString(),
        },
      },
      {
        popover: {
          description: i18n.t('chat_onboarding_step_13_text').toString(),
          showButtons: [ 'next' ],
          onNextClick: (_, __, opts) => {
            store.dispatch('setStartModules', { modules: {} })
                .then(() => {
                  opts.driver.destroy()

                  if (isMobile()) {
                    vm.$baseTemplate.drawer.toggleVisible()
                  }
                })
          }
        },
      }
    ]
  }
}

