import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class LeaveUsersHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.LeaveUsersHandlerModule;

  @Type(() => LeaveUsersHandlerModuleParams)
  params!: LeaveUsersHandlerModuleParams;
}

class LeaveUsersHandlerModuleParams {
  @Type(() => MessageEditorWithMediaData)
  leave_member_message!: Array<MessageEditorWithMediaData>

  after_leave_trigger!: string
}

