import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";
import { Type } from "class-transformer";

export default class VideoNoteFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.VideoNoteFilterHandlerModule;

  @Type(() => VideoNoteFilterHandlerModuleParams)
  params!: VideoNoteFilterHandlerModuleParams;
}

class VideoNoteFilterHandlerModuleParams {
    video_note_min_duaration!: number
    video_note_max_duaration!: number
    video_note_min_file_size!: number
    video_note_max_file_size!: number
}
