import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class ReferralModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ReferralModule;

  @Type(() => ReferralModuleParams)
  params!: ReferralModuleParams;
}

class ReferralModuleParams {

  referral_private_check_user!: boolean
  referral_accept_invite!: boolean

  referral_first_private_trigger!: string
  referral_first_private_trigger_new!: string
  referral_not_first_private_trigger!: string
  referral_not_first_private_trigger_new!: string
  referral_private_trigger!: string
  referral_private_trigger_new!: string

  @Type(() => MessageEditorWithMediaData)
  referral_private_message!: Array<MessageEditorWithMediaData>
}
