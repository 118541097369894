import { ChatConfig } from "@/includes/types/Chat/types";

import { TagData } from "piramis-base-components/src/components/Tags/types";
import { FieldData, SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { MessageEditorComponentSettings } from "piramis-base-components/src/components/NewMessageEditors/types";

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({})
export default class GroupTopicsMixin extends Vue {

  get topicsIfForum(): Record<'topics', any> | undefined {
    if (this.$store.getters.isChatForum) {
      return { 'topics': { items: this.chatTopicsOptions, limit: 1 } }
    }
  }

  get topicsAvailable(): Array<TagData> | null {
    const tags: Array<TagData> = [ { text: 'Starter', color: this.$store.state.themeConfig.config.tariffs.starter.color } ]

    if (!this.$store.getters.isChatLicenseExists) {
      return tags
    }

    return null
  }

  get chatTopicsOptions(): Array<SelectOptionData> | null {
    if (this.$store.getters.isChatSet && this.$store.getters.isChatForum) {
      const forumTopics: ChatConfig['topics'] = this.$store.state.chatState.chat?.topics

      if (forumTopics?.length) {
        return forumTopics.map(t => ({
          label: t.title,
          value: t.id.toString()
        }))
      }

      return []
    }

    return null
  }

  setEditorTopics(multiEditorInputData: FieldData & MessageEditorComponentSettings):void {
    if (this.$store.getters.isChatForum) {
      if (multiEditorInputData?.options === undefined) {
        this.$set(multiEditorInputData, 'options', {})
      }

      if (multiEditorInputData.options) {
        this.$set(multiEditorInputData.options, 'topics', this.topicsAvailable)
      }

      Object.assign(multiEditorInputData, this.topicsIfForum)
    }
  }
}
