import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import ReputationCalculationMode
  from "../ReputationMessageHandlerModule/ReputationCalculationMode/ReputationCalculationMode";
import { GreetingHandleTypeEnum } from "./types";
import {
  GreetingProtection,
  greetingProtectionSubTypes
} from "./protection";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Exclude, Type } from "class-transformer";

export default class NewUsersHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NewUsersHandlerModule;

  @Type(() => NewUsersHandlerModuleParams)
  params!: NewUsersHandlerModuleParams;
}

class NewUsersHandlerModuleParams {
  @Exclude()
  strict_mode?: boolean

  @Type(() => ReputationCalculationMode, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: greetingProtectionSubTypes
    },
  })
  protection!: GreetingProtection

  @Type(() => MessageEditorWithMediaData)
  hello_message!: Array<MessageEditorWithMediaData>

  greeting_handle_type!: GreetingHandleTypeEnum

  journal_users!: boolean

  enter_message_images_list!: Array<string>
}
