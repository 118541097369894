import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import store from "@/store/store";
import { AI_EXPERIMENTAL_TAG, ULTIMATE_TAG } from "@/includes/constants";
import KnowledgeBaseHandlerModule
  , { KnowledgeBaseArticles } from "@/includes/logic/Modules/modules/modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";
import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { plainToInstance } from "class-transformer";

export default class KnowledgeBaseHandlerModuleBuilder extends ModuleBuilder {
  model!: KnowledgeBaseHandlerModule;

  view: ModuleBuilder['view'] = () => import('./KnowledgeBaseHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.AI

  isVisible(): boolean {
    return store.getters.isChatSet && store.state.chatState.chat!.version >= 6000
  }

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'menu_book',
  }

  tags(): Array<TagData> | null {
    return AI_EXPERIMENTAL_TAG
  }

  tariffTags(): Array<TagData> | null {
    return store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG;
  }

  iterableItemFactory = new IterableItemFactory()

  getArticleTextTemplate() {
    return plainToInstance(KnowledgeBaseArticles, {
      text: [
        {
          attachments: [],
          text: '',
          buttons: [],
          remove_previous: false,
          pin: false,
          disable_link_preview: false,
          disable_notify: false,
          topics: [],
          protect_content: false,
          send_after: 0,
          remove_after: 0
        }
      ]
    })
  }

  setArticles(articles: Array<IterableListItem<KnowledgeBaseArticles>>) {
    this.model.params.articles = this.getRawArticles(articles)
  }

  getRawArticles(articles: Array<IterableListItem<KnowledgeBaseArticles>>) {
    return articles.map(a => a.value)
  }

  getFrontendArticles() {
    return this.model.params.articles.map(a => this.iterableItemFactory.create(a))
  }
}

