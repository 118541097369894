import { ModuleConditionTypeEnum, ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import SendAdminMessageModule
  from "@/includes/logic/Modules/modules/modules/SendAdminMessageModule/SendAdminMessageModule";

export default class SendAdminMessageModuleBuilder extends ModuleBuilder {
  model!: SendAdminMessageModule;

  view: ModuleBuilder['view'] = () => import('./SendAdminMessageModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  conditionsTypes: Array<ModuleConditionTypeEnum> = [
    ModuleConditionTypeEnum.WorkTime
  ]

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'av_timer',
  }

  aliases = [ 'Period', 'повышение активности', 'активность чата', 'активность', ]
}
