import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";

import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import RulesCommandHandlerModule
  from "@/includes/logic/Modules/modules/modules/RulesCommandHandlerModule/RulesCommandHandlerModule";

export default class RulesCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: RulesCommandHandlerModule

  view: ModuleBuilder['view'] = () => import('./RulesCommandHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'rule',
  }
}

