import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import WarnCommandHandlerModule from "./WarnCommandHandlerModule";

export default class WarnCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: WarnCommandHandlerModule;

  view: ModuleBuilder['view'] = () => import('./WarnCommandHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'feedback',
  }
}

