import ConditionBuilder from "../../ConditionBuilder";
import ResolveFromApCondition from "./ResolveFromApCondition";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "../../../types";

class ResolveFromApConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromAp;

  icon = 'exposure';

  view:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionValue.vue');

  getModel(): ConfigCondition {
    return new ResolveFromApCondition()
  }
}

export default new ResolveFromApConditionBuilder()
