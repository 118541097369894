import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import { TagData } from "piramis-base-components/src/components/Tags/types";
import XpHandlerModule from "./XpHandlerModule";

export default class XpHandlerModuleBuilder extends ModuleBuilder {
  model!: XpHandlerModule;

  view: ModuleBuilder['view'] = () => import('./XpHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'diamond',
    iconStyle: 'outlined'
  }

  aliases = [ 'очки', 'бал' ]

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('extended_reputation')
  }
}
