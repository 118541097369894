import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class NewUsersSafeInviteLinksModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NewUsersSafeInviteLinksModule;

  @Type(() => NewUsersSafeInviteLinksModuleParams)
  params!: NewUsersSafeInviteLinksModuleParams;
}

class NewUsersSafeInviteLinksModuleParams {
  links!: BlackWhiteListItem<string>
}
