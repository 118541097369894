import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import SaveModuleButton from "@/includes/logic/Modules/components/PageTitleButtons/SaveModuleButton.vue";
import GoBackButton from "@/includes/logic/Modules/components/PageTitleButtons/GoBackButton.vue";

import PageTitle from "piramis-base-components/src/components/PageTitle.vue";

import { Component, Emit, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({
  components: {
    PageTitle,
    SaveModuleButton,
    GoBackButton
  },
})
export default class ModuleBindings extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) hasBackButton!: boolean

  @Prop({ type: String, required: false }) backButtonTitle!: string

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  saveModule() {
    return null
  }

  @Emit()
  goBack() {
    return null
  }

  pageHelpMessageOrDescription(module: BaseModule) {
    if (this.$te(module.pageHelpMessageKey)) {
      return this.$t(module.pageHelpMessageKey).toString()
    } else if (this.$te(module.descriptionKey)) {
      return this.$t(module.descriptionKey).toString()
    } else {
      return null
    }
  }
}
