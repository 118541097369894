import {
  ActorTypeEnum,
  ReactionEmojiEnum
} from "@/includes/logic/Modules/modules/modules/ReputationMessageHandlerModule/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { RawChipItem } from "piramis-base-components/src/components/TypedChips/typedChips.types";

import { Type } from "class-transformer";

export default class ReputationTrigger {
  @Type(() => MessageEditorWithMediaData)
  message!: Array<MessageEditorWithMediaData>

  triggers!: Array<RawChipItem>

  min!: number
  max!: number

  actor_type!: ActorTypeEnum
  target_type!: ActorTypeEnum

  actor_reaction_emoji!: ReactionEmojiEnum | null
  target_reaction_emoji!: ReactionEmojiEnum | null
}
