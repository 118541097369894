import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class RulesCommandHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.RulesCommandHandlerModule;

  @Type(() => RulesCommandHandlerModuleParams)
  params!: RulesCommandHandlerModuleParams;
}

class RulesCommandHandlerModuleParams {
  @Type(() => MessageEditorWithMediaData)
  rules!: Array<MessageEditorWithMediaData>
}
