import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import DailyReportModule from "./DailyReportModule";

export default class DailyReportModuleBuilder extends ModuleBuilder {
  model!: DailyReportModule;

  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  view =  () => import('@/includes/logic/Modules/modules/modules/DailyReportModule/DailyReportModuleView.vue')

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'analytics',
    iconStyle: 'outlined'
  }

  hasReportUsers() {
    return !!Object.keys(this.model.params.report_users).length
  }
}

