import ConditionBuilder from "../../ConditionBuilder";
import ZoneCondition from "./ZoneCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import ConfigCondition from "../../ConfigCondition";

class ZoneConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Zone;

  icon = 'animation';

  view:ConditionBuilder['view'] = () => import('./ZoneConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('./ZoneConditionValue.vue');

  getModel(): ConfigCondition {
    return new ZoneCondition();
  }
}

export default new ZoneConditionBuilder()
