

































import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import ModulesApi from "@/includes/Api/Modules.api";
import { successNotification } from '@/includes/NotificationService';

import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { isChatManager } from "@/includes/logic/Modules/types";

@Component({
})
export default class SaveModuleButton extends Vue {

  @Prop({ type: Boolean, default: true }) isValid!: boolean

  @Prop({ type: Boolean, default: false }) disabled!: boolean

  get disabledValue() {
    return !this.isValid || this.disabled
  }

  @Emit()
  saveModule() {
    return null
  }

  get isCurrentModuleSaved() {
    return ModuleManagerState && ModuleManagerState.isCurrentModuleSaved
  }

  get saveButtonIcon() {
    return this.isCurrentModuleSaved
      ? 'save'
      : 'plus'
  }

  get saveButtonTitle() {
    return this.isCurrentModuleSaved
      ? this.$t('chat_config_save_button').toString()
      : this.$t('module_active_card_add_new').toString()
  }

  menuItemSelect(item: {key: string}) {
    if (item.key === 'export') {
      if (ModuleManagerState && ModuleManagerState.currentModule) {
        this.exportModule(ModuleManagerState.currentModule.model.guid)
      }
    }
  }

  exportModule(guid: ModuleBuilder['model']['guid']) {
    const chat_id = isChatManager(ModuleManagerState) ? this.$store.getters.chatId : this.$store.state.networksState.currentNetwork?.chats?.[0]

    if (typeof chat_id === 'number') {
      ModulesApi.exportModuleConfig({
        chat_id,
        guid
      })

      successNotification()
    }
  }
}
