import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class SendAdminMessageModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SendAdminMessageModule;

  @Type(() => SendAdminMessageModuleParams)
  params!: SendAdminMessageModuleParams;
}

class SendAdminMessageModuleParams {
  @Type(() => MessageEditorWithMediaData)
  admin_message!: Array<MessageEditorWithMediaData>

  admin_message_interval!: number
}
