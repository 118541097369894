import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import { Type } from "class-transformer";

export default class BotGuardHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.BotGuardHandlerModule;

  @Type(() => BotGuardHandlerModuleParams)
  params!: BotGuardHandlerModuleParams;
}

class BotGuardHandlerModuleParams {
  facecontrol_username_remove_bots!: boolean
  facecontrol_username_ban_bot_add_user!: boolean

  journal_facecontrol!: boolean
}
