import { BaseChatInfo, ChatConfigSettings } from "@/includes/types/Chat/types";
import { RootState } from "@/store/state";

import { cloneDeep } from "lodash";

export default {
	updateChatsInfo(state: RootState, n: {chats: Array<BaseChatInfo>}) {
		state.chatState.chatsInfo = n;
	},
	updateChat(state: RootState, n: ChatConfigSettings) {
		state.chatState.chat = n;

		// Put the copy of an actual config for rolling back
		state.chatState.backupConfig = cloneDeep(n);
	},
  ROLLBACK_CHAT_CONFIG(state: RootState) {
    if (state.chatState.chat && state.chatState.backupConfig) {
      state.chatState.chat = cloneDeep(state.chatState.backupConfig)
    }
  },
  PARTIAL_CONFIG_UPDATE(state: RootState, payload: Partial<ChatConfigSettings>) {
    state.chatState.chat = Object.assign(state.chatState.chat, payload)
  },
};
