import { Board } from "@/views/posts-planner/posts.types";
import { RootState } from "@/store/state";
import BotApi from "@/includes/Api/Bot.api";
import { errorNotification } from "@/includes/NotificationService";

import { Commit } from "vuex";

export default {
	requestPostBoards({ commit }: {commit: Commit}) {
    return BotApi.getPostBoards('tg')
        .then(({ data }) => data.boards)
        .catch((error) => {
          errorNotification(error)

          return []
        })
        .then(boards => {
          commit("set_boards_to_store", boards);
        })
	},
	setCurrentBoard({ commit, state }: {commit: Commit, state: RootState}, boardId: string) {
		if (state.postsPlanner.boards) {
			const board = (state.postsPlanner.boards ?? []).find((b: Board) => b.board.token_id === +boardId)

			if (board) {
				commit('set_current_board', board)

				return board
			}

			return null
		}

	}
};
