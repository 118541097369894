import ConditionBuilder from "../../ConditionBuilder";
import TopicsCondition from "./TopicsCondition";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import store from "@/store/store";

import { BlackWhiteListItemType } from "piramis-base-components/src/components/BlackWhiteList/types";

export class TopicsConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Topics;

  icon = 'forum';

  view:ConditionBuilder['view'] = () => import('./TopicsConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('./TopicsConditionValue.vue');

  getModel(): ConfigCondition {
    return new TopicsCondition()
  }

  getTitleKey(condition?: ConfigCondition) {
    if (condition instanceof TopicsCondition && condition?.values?.type === BlackWhiteListItemType.Blacklist) {
      return 'module_condition_topics_black_list_title'
    }

    return `module_condition_${ this.snakeCaseType }_title`
  }

  isVisibleInList(): boolean {
    return store.getters.isChatForum
  }
}

export default new TopicsConditionBuilder()
