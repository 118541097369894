import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import { AdminOrAllPermissions } from "@/includes/types/Enums";

import { Type } from "class-transformer";

export default class ApiNotifyHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ApiNotifyHandlerModule;

  @Type(() => ApiNotifyHandlerModuleParams)
  params!: ApiNotifyHandlerModuleParams;
}

class ApiNotifyHandlerModuleParams {
  api_notify_url!: string
  api_notify_types!: Array<ApiNotifyTypes>
}

export type ApiNotifyTypes = 'EnterNewMember' |
    'ReturnNewMember' |
    'CaptchaNotVerifiedKick' |
    'UserReEnterBan' |
    'KickSecondInactive' |
    'KickInactive' |
    'MemberLeave' |
    'MemberKicked'

