import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class XpHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.XpHandlerModule;

  @Type(() => XpHandlerModuleParams)
  params!: XpHandlerModuleParams;
}

class XpHandlerModuleParams {
  @Type(() => MessageEditorWithMediaData)
  xp_action_jack_pot_message!: Array<MessageEditorWithMediaData>

  xp_action_reward!: {
    RewardWriteFirstMessage?: {
      min: number
      max: number
    },
    RewardStartDialog?: {
      min: number
      max: number
    },
    RewardMention?: {
      min: number
      max: number
    },
    RewardWriteMessage?: {
      min: number
      max: number
    },
    RewardWriteReplyMessage?: {
      min: number
      max: number
    }
  }

  xp_min_message_len!: number

  xp_action_jack_pot_chance!: number

  xp_action_jack_pot_multiply!: number

  xp_bonus_interval!: number

  xp_bonus_message_count!: number

  xp_bonus_multiply!: number

  xp_bonus_time!: number

  @Type(() => MessageEditorWithMediaData)
  start_bonus_xp_message!: Array<MessageEditorWithMediaData>

  @Type(() => MessageEditorWithMediaData)
  end_bonus_xp_message!: Array<MessageEditorWithMediaData>
}
