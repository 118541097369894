import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import {
  ApproxStatFields,
  ChatMetrics, DashboardExtended, ExtendedCommunity,
  SummaryStatRecord, TriggerStatRecord
} from "@/includes/types/DashboardExtended/types";
import { BaseChatInfo } from "@/includes/types/Chat/types";
import { IBaseModule } from "@/includes/logic/Modules/types";

export type CreateNetworkReq = {
  name?: string
  groups: Array<number>
  config: ChatNetworkConfig
}

export type SetNetworkConfigReq = Pick<BaseNetworkData, 'id'> & Partial<CreateNetworkReq['config'] & Pick<CreateNetworkReq, 'groups'>>

export enum AccessModeEnum {
  All = 'All',
  Any = 'Any',
  Owner = 'Owner'
}

export type ChatNetworkConfig = Partial<{
  access_mode?: AccessModeEnum
  global_command_admins?: Array<number>
  ignored_users?: Array<number>
  triggers?: Array<number>
  modules?: Array<BaseModule>
}>

export type BaseNetworkData = {
  id: number
  name: string
  owner: number
  chats: Array<number>
  ignored_users: Array<number>
  global_command_admins: Array<number>
  triggers: Array<number>
  modules: Array<IBaseModule>
  access_mode: AccessModeEnum
}

export type NetworkConfig = BaseNetworkData & {
  chats_info: Array<BaseChatInfo>
  statistics_available: boolean
  group_without_triggers: Array<number>
  group_without_modules: Array<number>
  read_only: boolean
  board?: {
    token_id: number
    token_code: string
  }
}

export type NetworkStatChat = BaseChatInfo

export type NetworkStat = Pick<ExtendedCommunity, 'admin_users' | 'admin_users_stat' | 'admin_users_timeline'>
    & Pick<DashboardExtended, 'active_users_top'>
    & {
      summary_statistics: Array<Omit<SummaryStatRecord, keyof ApproxStatFields>>
      summary_statistics_by_group: Array<Omit<SummaryStatRecord, keyof ApproxStatFields> & { chat_id: BaseChatInfo['chat_id'] }>
      chats: Array<NetworkStatChat>
      triggers_stat: Array<TriggerStatRecord>
      metrics: ChatMetrics
    }
