import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";

import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import ReportCommandHandlerModule
  from "@/includes/logic/Modules/modules/modules/ReportCommandHandlerModule/ReportCommandHandlerModule";

export default class ReportCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: ReportCommandHandlerModule;

  view: ModuleBuilder['view'] = () => import('./ReportCommandHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'mark_chat_read',
  }

  aliases = [
      'жалоба',
    'report',
    'репорт',
    'admin',
  ]
}

