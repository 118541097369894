import { DeleteMessageActionBuilder } from "../PunishActions/actions/DeleteMessage/DeleteMessageActionBuilder";
import { ClassProperties } from "@/includes/types/util.types";
import { DeleteMessageAction } from "../PunishActions";
import i18n from "@/i18n/i18n";
import { FilterTypes } from "@/views/chat/filters/FilterTypes";

export class BanDeleteMessageBuilder extends DeleteMessageActionBuilder {
  constructor(filterType: FilterTypes, fields?: Partial<ClassProperties<DeleteMessageAction>>) {
    super();

    this.defaultModelValues = {
      message: [
        {
          text: i18n.t(`default_${ filterType.toLowerCase() }_warn_text_message`).toString(),
          remove_after: 90,
          send_after: 0,
          pin: false,
          disable_link_preview: true,
          disable_notify: false,
          buttons: [],
          attachments: [],
          topics: [],
          protect_content: false,
          remove_previous: true
        }
      ],
      ...fields
    }
  }
}
