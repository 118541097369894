import ConditionBuilder from "../../ConditionBuilder";
import WorkTimeCondition from "./WorkTimeCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import ConfigCondition from "../../ConfigCondition";

class WorkTimeConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.WorkTime;

  icon = 'access_time';

  view:ConditionBuilder['view'] = () => import('./WorkTimeConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('./WorkTimeConditionValue.vue');

  getModel(): ConfigCondition {
    return new WorkTimeCondition();
  }
}

export default new WorkTimeConditionBuilder()
