import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import { BasePunishActionModel, PunishActionSubTypes } from "../../../PunishActions/PunishActions";

import { Type } from "class-transformer";

export default class EnterBindedChannelHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.EnterBindedChannelHandlerModule;

  @Type(() => EnterBindedChannelHandlerModuleParams)
  params!: EnterBindedChannelHandlerModuleParams;
}

class EnterBindedChannelHandlerModuleParams {

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  ban_action!: BasePunishActionModel

  binded_channel_id!: number
  journal!: boolean
}
