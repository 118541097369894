import { BaseChatInfo } from "@/includes/types/Chat/types";
import { RawTriggerData, TriggersStatistics } from "@/components/TriggerSetup/logic/types/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";
import { EditChatTriggersReq, GetChatTriggersRes } from "@/includes/types/triggers";

export default class TriggersApi {
  static async getChatTriggers<T extends boolean>(chat_id: BaseChatInfo['chat_id'], active = true) {
    return PostApi.sendRequest<GetChatTriggersRes<T>>("tg", 'getchattriggers', { chat_id, active })
  }

  static async setTrigger(trigger: RawTriggerData) {
    return PostApi.sendRequest<RawTriggerData>("tg", 'settrigger', trigger)
  }

  static async getTrigger(id: Required<Pick<RawTriggerData, 'id'>>['id']) {
    return PostApi.sendRequest<{ trigger: RawTriggerData }>("tg", 'gettrigger', { id })
  }

  static async getChatTriggersStat(chat_id: BaseChatInfo['chat_id']) {
    return PostApi.sendRequest<TriggersStatistics>("tg", 'getchattriggersstat', { chat_id })
  }

  static async editChatTriggers(payload: EditChatTriggersReq) {
    return PostApi.sendRequest<GetChatTriggersRes<true>>("tg", 'editchattriggers', payload)
  }
}
