import ConditionBuilder from "../../ConditionBuilder";
import UsersCondition from "./UsersCondition";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";

class UsersConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Users;

  icon = 'group';

  view:ConditionBuilder['view'] = () => import('./UsersConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('./UsersConditionValue.vue');

  getModel(): ConfigCondition {
    return new UsersCondition();
  }

}

export default new UsersConditionBuilder()
