import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import AlbumLimitHandlerModule from "./AlbumLimitHandlerModule";

export default class AlbumLimitHandlerModuleBuilder extends ModuleBuilder {
  model!: AlbumLimitHandlerModule;

  view: ModuleBuilder['view'] = () => import('./AlbumLimitHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'filter',
  }
}

