import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class ChannelReplyFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ChannelReplyFilterHandlerModule;

  @Type(() => ChannelReplyFilterHandlerModuleParams)
  params!: ChannelReplyFilterHandlerModuleParams
}

class ChannelReplyFilterHandlerModuleParams {
  channels_whitelist!: BlackWhiteListItem<string>
}
