import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import FirstCommentHandlerModule from "./FirstCommentHandlerModule";

export default class FirstCommentHandlerModuleBuilder extends ModuleBuilder {
  model!: FirstCommentHandlerModule;

  view: ModuleBuilder['view'] = () => import('./FirstCommentHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'add_comment',
  }
}

