import { BasePunishActionModel, PunishActionTypesEnum } from "./PunishActions";
import { ClassProperties } from "@/includes/types/util.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { snakeCase } from "lodash";

export abstract class BasePunishActionBuilder<T = BasePunishActionModel> {
  abstract type: PunishActionTypesEnum

  abstract icon: string;

  abstract view?: VueComponent

  abstract getModel(): T

  defaultModelValues: Partial<ClassProperties<T>> | undefined

  actionTitleKey() {
    if (this.type) {
      return `punish_action_type_${ snakeCase(this.type) }`
    }

    return ''
  }

  actionHelpMessageKey() {
    if (this.type) {
      return `${ this.actionTitleKey() }_help_message`
    }

    return ''
  }

  previewActionTitleKey() {
    return this.actionTitleKey()
  }
}
