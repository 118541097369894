import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { Type } from "class-transformer";

export default class CapsMessageFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.CapsMessageFilterHandlerModule;

  @Type(() => CapsMessageFilterHandlerModuleParams)
  params!: CapsMessageFilterHandlerModuleParams
}

class CapsMessageFilterHandlerModuleParams {
    caps_message_strict_mode!: boolean
    caps_message_whitelist!: Array<string>
}
