import ConditionBuilder from "../../ConditionBuilder";
import ResolveFromRankCondition from "./ResolveFromRankCondition";
import store from "@/store/store";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import { TagData } from "piramis-base-components/src/components/Tags/types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper";
import i18n from "@/i18n/i18n";
import Vue from "vue";

class ResolveFromRankConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromRank;

  icon = 'military_tech';

  view:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionValue.vue');

  isDisabled() {
    return store.getters.isChatAtLeastAdvanced && !store.getters.isChatLicenseExists
  }

  getModel(): ConfigCondition {
    return new ResolveFromRankCondition();
  }

  tariffTag(): Array<TagData> | null {
    if (store.state.chatState.chat && store.state.chatState.chat.limits) {
      if (!store.state.chatState.chat.limits.options.includes('extended_reputation')) {
        const optionTariff = Vue.prototype.$getOptionTariff('extended_reputation')
        const translationKey  = i18n.t(getTariffTranslationKey(optionTariff))

        return [ {
          'text':  translationKey.toString(),
          'color': store.state.themeConfig.getTariff(translationKey).color
        } ]
      }

      return null
    }

    return null
  }
}

export default new ResolveFromRankConditionBuilder()
