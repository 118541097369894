import NetworksApi from "@/includes/Api/Networks.api";
import {
  AccessModeEnum,
  CreateNetworkReq,
  BaseNetworkData,
  SetNetworkConfigReq, NetworkConfig
} from "@/includes/types/networks";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import store from "@/store/store";
import i18n from "@/i18n/i18n";
import { BaseChatInfo, ChatConfigSettings } from "@/includes/types/Chat/types";
import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { NetworkModulesManager, setModules } from "@/includes/logic/Modules/ModulesManager";
import { NetworkConfigImport } from "@/includes/types/ImportConfig.types";

import TokenId from "piramis-base-components/src/shared/utils/TokenId";

import { cloneDeep, isEqual } from "lodash";
import Vue from "vue";
import { instanceToPlain } from "class-transformer";

export function initialiseNetworkModel(): CreateNetworkReq {
  return cloneDeep({
    name: '',
    groups: [],
    config: {
      global_command_admins: [],
      access_mode: AccessModeEnum.Owner,
      ignored_users: [],
    }
  })
}

export function sortNetworksByChatsLength(networks: Array<BaseNetworkData>) {
  return cloneDeep(networks).sort((a, b) => b.chats.length - a.chats.length)
}

function prepareNetwork(network: CreateNetworkReq) {
  return new Promise<CreateNetworkReq>((resolve) => {
    if (!network.name?.trim()) {
      delete network.name
    }

    return resolve(network)
  })
}

export function createNetwork(network: CreateNetworkReq) {
  return prepareNetwork(network)
      .then(resNetwork => {
        return NetworksApi.createNetwork('tg', resNetwork)
            .then(({ data }) => {
              successNotification(i18n.t('network_created_notify').toString())

              // store.commit('ADD_NETWORK', data)

              return data
            })
            .catch(errorNotification)
      })
      .catch(errorNotification)
}

export function deleteNetwork(id: BaseNetworkData['id']) {
  return NetworksApi.deleteNetwork('tg', id)
      .then(res => {
        store.commit('CLEAR_CURRENT_NETWORK')

        successNotification(i18n.t('network_removed_notify').toString())

        return res
      })
      .catch(errorNotification)
}

export function getMyNetworks() {
  return NetworksApi.getMyNetworks("tg")
      .then(({ data }) => data.networks)
      .catch(e => {
        errorNotification(e)

        return [] as Array<BaseNetworkData>
      })
}

export function getNetworkConfig(networkId: BaseNetworkData['id']) {
  return NetworksApi.getNetworkConfig('tg', networkId)
      .then(res => {
        store.commit('SET_CURRENT_NETWORK', res.data)

        setModules({ modules: res.data.modules }, NetworkModulesManager)

        return res.data
      })
      .catch(error => {
          store.dispatch('pushNetworkError', error)
      })
}

export function getNetworkTriggers(networkId: BaseNetworkData['id']) {
  if (store.state.networksState.triggers.loadingTriggerPromise === null) {
    const promise = NetworksApi.getNetworkTriggers('tg', networkId)
        .then(({ data }) => {
          store.commit('SET_NETWORK_TRIGGERS', data.triggers)

          return data.triggers
        })
        .catch(error => {
          errorNotification(error)

          store.commit('SET_NETWORK_TRIGGERS', [])

          return [] as Array<RawTriggerData>
        })
        .finally(() => {
          store.commit('setObjectField', {
            object: store.state.networksState.triggers,
            objectPath: "loadingTriggerPromise",
            value: null,
          })
        })

    store.commit('setObjectField', {
      object: store.state.networksState.triggers,
      objectPath: "loadingTriggerPromise",
      value: promise,
    })

    return promise
  } else {
    return store.state.networksState.triggers.loadingTriggerPromise
  }
}

export function setNetworkConfig(networkId: SetNetworkConfigReq['id'], body: Omit<SetNetworkConfigReq, 'id'>) {
  return NetworksApi.setNetworkConfig("tg", { ...body, id: networkId })
      .then(({ data }) => {
        store.commit('SET_CURRENT_NETWORK', data)

        successNotification(i18n.t('configuration_saved').toString())

        return data
      })
      .catch(errorNotification)
}

// triggers
export function deleteNetworkTrigger(networkId: BaseNetworkData['id'], triggerId: string) {
  return new Promise<NetworkConfig | void>((resolve) => {
    Vue.prototype.$confirm({
      title: i18n.t("trigger_confirm_remove").toString(),
      content: i18n.t("trigger_confirm_remove_text").toString(),
      okText: i18n.t("accept").toString(),
      cancelText: i18n.t("reject").toString(),
      onOk: () => {
        Vue.prototype.$baseTemplate.loader.open()

        NetworksApi.deleteNetworkTrigger('tg', networkId, triggerId)
            .then(({ data }) => {

              store.commit('UPDATE_CURRENT_NETWORK', { triggers: data.triggers })
              store.commit('REMOVE_NETWORK_TRIGGER', triggerId)

              successNotification(i18n.t('trigger_notify_deleted').toString())

              resolve(store.state.networksState.currentNetwork!)
            })
            .catch(errorNotification)
            .finally(() => {
              Vue.prototype.$baseTemplate.loader.close()
            })
      }
    })
  })
}

export function saveNetworkTrigger(network: BaseNetworkData, triggerId: string, rawTriggerData: RawTriggerData) {
  return NetworksApi.setNetworkTrigger('tg', network.id, triggerId, rawTriggerData)
      .then(({ data }) => {
        const triggers = network.triggers

        if (triggers) {
          triggers.push(+TokenId.getId(data.id))

          store.commit('UPDATE_CURRENT_NETWORK', { triggers })

          successNotification(i18n.t('trigger_notify_saved').toString())
        }

        return data
      })
      .catch(errorNotification)
}

//
export function removeGroupFromNetwork(chatId: BaseChatInfo['chat_id'], networkId: BaseNetworkData['id']) {
  return new Promise<ChatConfigSettings | void>((resolve) => {
    Vue.prototype.$confirm({
      title: i18n.t('network_confirm_remove_chat_title').toString(),
      content: i18n.t('network_confirm_remove_chat_content').toString(),
      okText: i18n.t('delete').toString(),
      onOk: () => {
        NetworksApi.removeGroupFromNetwork('tg', chatId, networkId)
            .then(({ data }) => {
              successNotification(i18n.t('network_chat_remove_notify_removed').toString())

              resolve(data)
            })
            .catch(errorNotification)
      }
    })
  })
}

export function applyNetworkConfigToGroup({ network, chat_id, import_types }: NetworkConfigImport) {
  return NetworksApi.applyNetworkConfigToGroup('tg', { network, chat_id, import_types })
      .then(({ data }) => {
        return store.dispatch('updateChatState', data)
            .then(() => data.chat)
      })
      .catch(errorNotification)
}

export function setNetworkModuleConfig(network_id: BaseNetworkData['id'], module: BaseModule) {
  return NetworksApi.setNetworkModuleConfig('tg', {
    module: instanceToPlain(module),
    network_id
  })
      .then(res => {
        if (res) {
          store.commit('UPDATE_CURRENT_NETWORK', { modules: res.data.modules })

          setModules({ modules: res.data.modules }, NetworkModulesManager)

          return res.data.modules
        }
      })
      .catch(errorNotification)
}

export function isConfigChanged(newConfig: BaseNetworkData) {
  return !isEqual(newConfig, store.state.networksState.currentNetwork)
}
