import { UserCommunicationStatByPeriodRes, UsersProfileInfo, UsersProfileStat } from "@/includes/types/UserProfile/types";

import { SuccessGetUser } from "piramis-base-components/src/components/Flow/logic/types/type";
import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class UserProfileApi {
  static async getUsersProfileInfo (chat_id: number, user_ids: Array<number>): AxiosPromise<UsersProfileInfo> {
    return PostApi.sendRequest('tg', 'getusersprofileinfo', { chat_id, user_ids })
  }

  static async getUsersProfileStat (chat_id: number, user_ids: Array<number>, from:string, to:string) {
    return PostApi.sendRequest<UsersProfileStat>('tg', 'getusersprofilestat', { chat_id, user_ids, from, to })
  }

  static async getUsersInfo(chat_id: number, values: Array<number | string>): AxiosPromise<{ data: Array<SuccessGetUser> }> {
    return PostApi.sendRequest('tg', 'getusersinfo', { chat_id, values })
  }

  static async getCommunicationStatisticsByPeriod (body: any) {
    return PostApi.sendRequest<UserCommunicationStatByPeriodRes>('tg', 'getcommunicationstatisticsbyperiod', body)
  }
}
