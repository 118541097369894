import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import EditMediaHandlerModule from "./EditMediaHandlerModule";

export default class EditMediaHandlerModuleBuilder extends ModuleBuilder {
  model!: EditMediaHandlerModule;

  view: ModuleBuilder['view'] = () => import('./EditMediaHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'delete_sweep',
    iconStyle: "outlined",
  }
}

