import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import NsfwHandlerModule from "./NsfwHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";
import { AI_EXPERIMENTAL_TAG } from "@/includes/constants";

export default class NsfwHandlerModuleBuilder extends ModuleBuilder {
  model!: NsfwHandlerModule;

  view: ModuleBuilder['view'] = () => import('./NsfwHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.AI

  icon: ModuleIcon = {
    type: "Pack",
    name: 'favorite',
    pack: "material-icons",
  }

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('delete_porno')
  }

  tags(): Array<TagData> | null {
    return AI_EXPERIMENTAL_TAG
  }
}

