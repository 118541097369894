import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import { ReactionEmojiEnum } from "@/includes/logic/Modules/modules/modules/ReputationMessageHandlerModule/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class FirstCommentHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.FirstCommentHandlerModule;

  @Type(() => FirstCommentHandlerModuleParams)
  params!: FirstCommentHandlerModuleParams;
}

class FirstCommentHandlerModuleParams {
  @Type(() => MessageEditorWithMediaData)
  messages!: Array<MessageEditorWithMediaData>

  reaction!: ReactionEmojiEnum
}
