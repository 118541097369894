import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import RanksHandlerModule from "@/includes/logic/Modules/modules/modules/RanksHandlerModule/RanksHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class RanksHandlerModuleBuilder extends ModuleBuilder {
  model!: RanksHandlerModule;

  view: ModuleBuilder['view'] = () => import('./RanksHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'workspace_premium',
  }

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('extended_reputation')
  }
}
