import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";

export default class HasAchievementModuleConfig extends ConfigCondition {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.HasAchievementModuleConfig;

  value: Array<number> = []
  exclude = false

  async validate(): Promise<boolean> {
    return !!this.value.length
  }
}
