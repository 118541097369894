import { IBaseModule, ModuleTypesEnum } from "@/includes/logic/Modules/types";
import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ChatConfig, ChatConfigSettings } from "@/includes/types/Chat/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { instanceToPlain } from "class-transformer";
import { AxiosPromise } from "axios";

export default class ModulesApi {
  static async getDefaultModuleConfig (chat_id: number, type: ModuleTypesEnum): AxiosPromise<{ module: IBaseModule}> {
    return PostApi.sendRequest('tg', 'getdefaultmoduleconfig', { chat_id, type })
  }

  static async setModuleConfig (chat_id: number, module: BaseModule): AxiosPromise<{ chat: ChatConfig }> {
    return PostApi.sendRequest('tg', 'setmoduleconfig', { chat_id, module: instanceToPlain(module) })
  }

  static async saveSearchContent(chat_id: number, content: string): AxiosPromise<void> {
    return PostApi.sendRequest('tg', 'savesearchcontent', { chat_id, content })
  }

  static exportModuleConfig(params: { chat_id: number, guid: string }) {
    window.open(`${ PostApi.getRequestUrl('tg', "exportmoduleconfig") }?chat_id=${ params.chat_id }&guid=${ params.guid }`, "_blank");
  }

  static async importModuleConfig(formData: FormData): AxiosPromise<{ chat: ChatConfigSettings }> {
    return PostApi.sendRequest('tg', 'importmoduleconfig', formData)
  }
}
