




































import { ConfigConditionTypes } from '@/includes/logic/Modules/Conditions/ConfigConditionTypes'
import ConfigCondition from '@/includes/logic/Modules/Conditions/ConfigCondition'
import { ModuleConditionTypeEnum } from '@/includes/logic/Modules/types'
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";
import ConditionsConfigService from "@/includes/logic/Modules/Conditions/ConditionsConfigService";

import { TagData } from "piramis-base-components/src/components/Tags/types";
import WizardLikeSelect from "piramis-base-components/src/components/WizardLikeSelect/WizardLikeSelect.vue";
import { WizardLikeList, IWizardLikeListItem } from "piramis-base-components/src/components/WizardLikeSelect/types";
import WizardLikeListItems from 'piramis-base-components/src/components/WizardLikeSelect/WizardLikeListItems.vue';
import { IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory';

import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { instanceToInstance } from "class-transformer";
import { cloneDeep } from "lodash";

@Component({
  components: {
    WizardLikeSelect,
    WizardLikeListItems,
  }
})
export default class SelectCondition extends Mixins(TariffsTagsHelper) {
  @VModel() isSelectConditionModalOpen!: boolean

  @Prop() conditionsTypes!: Array<ModuleConditionTypeEnum>

  @Prop() disabled!: boolean

  @Prop() currentCondition!: IterableListItem<ConfigCondition> | null

  @Prop() usersInfoService!: GetUserInfoServiceImpl

  @Prop() builders!: ConditionsConfigService["conditionBuilders"]

  @Emit()
  conditionSelected(condition: ConfigCondition) {
    return condition
  }

  @Emit()
  resetState() {
    return null
  }

  @Watch('currentCondition')
  onCurrentConditionChange(value: IterableListItem<ConfigCondition> | null) {
    if (value) {
      this.currentKey = value.value.type
      this.condition = cloneDeep(value.value)
    } else {
      this.currentKey = value
      this.condition = null
    }
  }

  isModalOpen = false

  condition: ConfigCondition | null = null

  currentKey: ModuleConditionTypeEnum | null = null

  isSaving = false

  get listData(): WizardLikeList {
    const items = ConfigConditionTypes
      .filter(c => this.conditionsTypes ? this.conditionsTypes.includes(c.name) : true)
      .reduce((acc: Array<IWizardLikeListItem>, value) => {
        const builder = this.builders[value.name]

        if (builder) {
          acc.push({
            key: value.name,
            title: this.$t(builder.getTitleKey()).toString(),
            helpMessage: this.$te(builder.helpMessageKey) ? this.$t(builder.helpMessageKey).toString() : null,
            icon: builder.icon,
            tag: builder.tariffTag(),
            isVisible: builder.isVisibleInList(),
            disabled: {
              state: builder.isDisabled.bind(builder)
            }
          })
        }

        return acc
      }, [])

    return [
      {
        type: "Simple",
        items
      }
    ]
  }

  setCurrentBuilder(type: ModuleConditionTypeEnum | null) {
    if (!type) {
      this.condition = type
    } else {
      if (this.currentCondition && type === this.currentCondition.value.type) {
        this.condition = instanceToInstance(this.currentCondition.value)
      } else {
        this.condition = this.builders[type].getModel()
      }
    }
  }

  async saveItem(_: IWizardLikeListItem['key'] | null, closeModalCallback: () => void) {
    if (this.condition) {
      this.isSaving = true

      try {
        const res = await this.condition.validate()

        if (res) {
          this.conditionSelected(this.condition)

          closeModalCallback()
        }
      } finally {
        this.isSaving = false
      }
    }
  }
}
