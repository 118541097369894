import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class PermittedWordFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.PermittedWordFilterHandlerModule;

  @Type(() => PermittedWordFilterHandlerModuleParams)
  params!: PermittedWordFilterHandlerModuleParams
}

class PermittedWordFilterHandlerModuleParams {
    permitted_words!: BlackWhiteListItem<string>
}
