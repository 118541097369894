import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";
import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";
import { RawChipItem } from "piramis-base-components/src/components/TypedChips/typedChips.types";
import { Type } from "class-transformer";

export default class StickerPackFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.StickerPackFilterHandlerModule;

  @Type(() => StickerPackFilterHandlerModuleParams)
  params!: StickerPackFilterHandlerModuleParams
}

class StickerPackFilterHandlerModuleParams {
  sticker_pack_items!: BlackWhiteListItem<RawChipItem>
}
