import ConditionBuilder from "@/includes/logic/Modules/Conditions/ConditionBuilder";
import HasAchievementModuleConfig from "./HasAchievementModuleConfig";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";
import { TagData } from "piramis-base-components/src/components/Tags/types";

class HasAchievementModuleConfigBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.HasAchievementModuleConfig;

  icon: string = 'star';
  view: VueComponent = () => import('./HasAchievementModuleConfigView.vue');
  preview: VueComponent = () => import('./HasAchievementModuleConfigValue.vue');

  getModel(): ConfigCondition {
    return new HasAchievementModuleConfig()
  }

  tariffTag(): Array<TagData> | null {
    return store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
  }

  isDisabled(): boolean {
    return !!this.tariffTag()
  }
}

export default new HasAchievementModuleConfigBuilder()
