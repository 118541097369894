import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";
import { Type } from "class-transformer";

export default class MessageLengthFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.MessageLengthFilterHandlerModule;

  @Type(() => MessageLengthFilterHandlerModuleParams)
  params!: MessageLengthFilterHandlerModuleParams
}

class MessageLengthFilterHandlerModuleParams {
    message_length_min_lines!: number
    message_length_max_lines!: number

    message_length_min!: number
    message_length_max!: number

    message_length_words_count_min!: number
    message_length_words_count_max!: number
}
