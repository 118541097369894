import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";
import { StickerMessageTypeEnum } from "@/includes/logic/Modules/modules/modules/StickerFilterHandlerModule/types";

import { Type } from "class-transformer";

export default class StickerFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.StickerFilterHandlerModule;

  @Type(() => StickerFilterHandlerModuleParams)
  params!: StickerFilterHandlerModuleParams;
}

class StickerFilterHandlerModuleParams {
  message_type!: StickerMessageTypeEnum
}
