import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class NewUsersLimitHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NewUsersLimitHandlerModule;

  @Type(() => NewUsersLimitHandlerModuleParams)
  params!: NewUsersLimitHandlerModuleParams;
}

class NewUsersLimitHandlerModuleParams {
  new_user_limit!: number

  @Type(() => MessageEditorWithMediaData)
  facecontrol_warning_message!: Array<MessageEditorWithMediaData>

  journal_facecontrol!: boolean
}
