import { RootState } from "@/store/state";
import { AchievementItem } from "@/includes/types/Achievements.types";

import { SelectOption } from "piramis-base-components/src/logic/types";

const getters = {
  achievementOptions: (state: RootState): Array<SelectOption> => {
    if (state.chatState.chat) {
      return state.chatState.chat.common_config.achievements.map((a: AchievementItem) => ({
        label: a.title,
        value: a.id
      }))
    }

    return []
  },
}

export default getters;
