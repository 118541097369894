import i18n from "@/i18n/i18n";
import { successNotification, errorNotification } from "@/includes/NotificationService";
import { RunChatActionType } from "@/includes/types/Chat/types";
import ChatApi from "@/includes/Api/Chat.api";
import { RootState } from "@/store/state";
import { ChatModulesManager, setModules } from "@/includes/logic/Modules/ModulesManager";

import Vue from "vue";
import { Commit, Dispatch } from "vuex";

export default {
  runChatAction({ state, commit, dispatch }: { state: RootState, commit: Commit, dispatch: Dispatch }, payload: {action: RunChatActionType, chat_id: number}) {
    Vue.prototype.$confirm({
      title: i18n.t("confirm_save_chat_config_title").toString(),
      content: i18n.t("confirm_chat_action").toString(),
      okText: i18n.t("accept").toString(),
      cancelText: i18n.t("reject").toString(),
      onOk: () => {
        dispatch('runChatActionReq', payload)
      },
    });
  },
  runChatActionReq({ state, commit }: { state: RootState, commit: Commit }, payload: {action: RunChatActionType, chat_id: number}) {
        Vue.prototype.$baseTemplate.loader.open();

        return ChatApi.runChatAction({
          chat_id: payload.chat_id,
          action: payload.action
        })
            .then(({ data }) => {
              commit("updateChat", data.chat);
              setModules(data.chat.modules_config, ChatModulesManager)

              successNotification(i18n.t("done").toString());

              return data.chat
            })
            .catch(errorNotification)
            .finally(() => {
              Vue.prototype.$baseTemplate.loader.close();
            });
      },
};
