












import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import BaseProtectionTypeFields
  from "@/includes/logic/Modules/modules/modules/NewUsersHandlerModule/components/ProtectionTypes/BaseProtectionTypeFields.vue";
import ExternalValidationGreetingProtection
  from "@/includes/logic/Modules/modules/modules/NewUsersHandlerModule/protection/ExternalValidationGreetingProtection";

@Component({
  components: {
    BaseProtectionTypeFields
  }
})
export default class ExternalValidationProtectionView extends Vue {
  @VModel() model!: ExternalValidationGreetingProtection

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean
}
