import { ModuleTypesEnum } from "./types";
import BaseModule from "./modules/BaseModule";
import ReferralModule from "./modules/modules/ReferralModule/ReferralModule";
import AnchorsHandlerModule from "./modules/modules/AnchorsHandlerModule/AnchorsHandlerModule";
import ApiNotifyHandlerModule
  from "./modules/modules/ApiNotifyHandlerModule/ApiNotifyHandlerModule";
import ReportCommandHandlerModule
  from "./modules/modules/ReportCommandHandlerModule/ReportCommandHandlerModule";
import ChatTopCommandHandlerModule
  from "./modules/modules/ChatTopCommandHandlerModule/ChatTopCommandHandlerModule";
import ModerateCommandHandlerModule
  from "./modules/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModule";
import CommandLimitHandlerModule
  from "./modules/modules/CommandLimitHandlerModule/CommandLimitHandlerModule";
import RulesCommandHandlerModule
  from "./modules/modules/RulesCommandHandlerModule/RulesCommandHandlerModule";
import SendWakeupMessageModule
  from "./modules/modules/SendWakeupMessageModule/SendWakeupMessageModule";
import SendAdminMessageModule
  from "./modules/modules/SendAdminMessageModule/SendAdminMessageModule";
import EntertainmentHandlerModule
  from "./modules/modules/EntertainmentHandlerModule/EntertainmentHandlerModule";
import EntertainmentCallHandlerModule
  from "./modules/modules/EntertainmentCallHandlerModule/EntertainmentCallHandlerModule";
import ChatCryptoCommandHandlerModule
  from "./modules/modules/ChatCryptoCommandHandlerModule/ChatCryptoCommandHandlerModule";
import InactiveKickHandlerModule
  from "./modules/modules/InactiveKickHandlerModule/InactiveKickHandlerModule";
import FirstInactiveKickHandlerModule
  from "./modules/modules/FirstInactiveKickHandlerModule/FirstInactiveKickHandlerModule";
import DailyReportModule from "./modules/modules/DailyReportModule/DailyReportModule";
import AlbumLimitHandlerModule
  from "./modules/modules/AlbumLimitHandlerModule/AlbumLimitHandlerModule";
import CloseChatHandlerModule
  from "./modules/modules/CloseChatHandlerModule/CloseChatHandlerModule";
import AutocorrectionHandlerModule
  from "./modules/modules/AutocorrectionHandlerModule/AutocorrectionHandlerModule";
import NewUsersAttackHandlerModule
  from "./modules/modules/NewUsersAttackHandlerModule/NewUsersAttackHandlerModule";
import NsfwHandlerModule from "./modules/modules/NsfwHandlerModule/NsfwHandlerModule";
import EditMediaHandlerModule
  from "./modules/modules/EditMediaHandlerModule/EditMediaHandlerModule";
import FacecontrolHandlerModule
  from "./modules/modules/FacecontrolHandlerModule/FacecontrolHandlerModule";
import MessageBindedChannelHandlerModule
  from "./modules/modules/MessageBindedChannelHandlerModule/MessageBindedChannelHandlerModule";
import NewUsersLimitHandlerModule
  from "./modules/modules/NewUsersLimitHandlerModule/NewUsersLimitHandlerModule";
import BotGuardHandlerModule
  from "./modules/modules/BotGuardHandlerModule/BotGuardHandlerModule";
import EnterBindedChannelHandlerModule
  from "./modules/modules/EnterBindedChannelHandlerModule/EnterBindedChannelHandlerModule";
import StopWordFilterHandlerModule
  from "./modules/modules/StopWordFilterHandlerModule/StopWordFilterHandlerModule";
import StoryFilterHandlerModule
  from "./modules/modules/StoryFilterHandlerModule/StoryFilterHandlerModule";
import VideoNoteFilterHandlerModule
  from "./modules/modules/VideoNoteFilterHandlerModule/VideoNoteFilterHandlerModule";
import SpoilerMessageFilterHandlerModule
  from "./modules/modules/SpoilerMessageFilterHandlerModule/SpoilerMessageFilterHandlerModule";
import ContactFilterHandlerModule
  from "./modules/modules/ContactFilterHandlerModule/ContactFilterHandlerModule";
import DiceFilterHandlerModule
  from "./modules/modules/DiceFilterHandlerModule/DiceFilterHandlerModule";
import AudioFilterHandlerModule
  from "./modules/modules/AudioFilterHandlerModule/AudioFilterHandlerModule";
import HieroglyphMessageFilterHandlerModule
  from "./modules/modules/HieroglyphMessageFilterHandlerModule/HieroglyphMessageFilterHandlerModule";
import RtlMessageFilterHandlerModule
  from "./modules/modules/RtlMessageFilterHandlerModule/RtlMessageFilterHandlerModule";
import VoiceFilterHandlerModule
  from "./modules/modules/VoiceFilterHandlerModule/VoiceFilterHandlerModule";
import AnimationFilterHandlerModule
  from "./modules/modules/AnimationFilterHandlerModule/AnimationFilterHandlerModule";
import VideoFilterHandlerModule
  from "./modules/modules/VideoFilterHandlerModule/VideoFilterHandlerModule";
import PhotoFilterHandlerModule
  from "./modules/modules/PhotoFilterHandlerModule/PhotoFilterHandlerModule";
import DocumentFilterHandlerModule
  from "./modules/modules/DocumentFilterHandlerModule/DocumentFilterHandlerModule";
import RegularExpressionFilterHandlerModule
  from "./modules/modules/RegularExpressionFilterHandlerModule/RegularExpressionFilterHandlerModule";
import PermittedWordFilterHandlerModule
  from "./modules/modules/PermittedWordFilterHandlerModule/PermittedWordFilterHandlerModule";
import CensureFilterHandlerModule
  from "./modules/modules/CensureFilterHandlerModule/CensureFilterHandlerModule";
import CapsMessageFilterHandlerModule
  from "./modules/modules/CapsMessageFilterHandlerModule/CapsMessageFilterHandlerModule";
import MessageLengthFilterHandlerModule
  from "./modules/modules/MessageLengthFilterHandlerModule/MessageLengthFilterHandlerModule";
import ChannelMessageFilterHandlerModule
  from "./modules/modules/ChannelMessageFilterHandlerModule/ChannelMessageFilterHandlerModule";
import AnyMessageFilterHandlerModule
  from "./modules/modules/AnyMessageFilterHandlerModule/AnyMessageFilterHandlerModule";
import ChatReplyFilterHandlerModule
  from "./modules/modules/ChatReplyFilterHandlerModule/ChatReplyFilterHandlerModule";
import ChannelReplyFilterHandlerModule
  from "./modules/modules/ChannelReplyFilterHandlerModule/ChannelReplyFilterHandlerModule";
import BotReplyFilterHandlerModule
  from "./modules/modules/BotReplyFilterHandlerModule/BotReplyFilterHandlerModule";
import StickerFilterHandlerModule
  from "./modules/modules/StickerFilterHandlerModule/StickerFilterHandlerModule";
import FloodFilterHandlerModule
  from "./modules/modules/FloodFilterHandlerModule/FloodFilterHandlerModule";
import BotCommandFilterHandlerModule
  from "./modules/modules/BotCommandFilterHandlerModule/BotCommandFilterHandlerModule";
import StickerPackFilterHandlerModule
  from "./modules/modules/StickerPackFilterHandlerModule/StickerPackFilterHandlerModule";
import EmailLinksFilterHandlerModule
  from "./modules/modules/EmailLinksFilterHandlerModule/EmailLinksFilterHandlerModule";
import EmojiFilterHandlerModule
  from "./modules/modules/EmojiFilterHandlerModule/EmojiFilterHandlerModule";
import ReferralFilterHandlerModule
  from "./modules/modules/ReferralFilterHandlerModule/ReferralFilterHandlerModule";
import WebLinksFilterHandlerModule
  from "./modules/modules/WebLinksFilterHandlerModule/WebLinksFilterHandlerModule";
import SelfLinksFilterHandlerModule
  from "./modules/modules/SelfLinksFilterHandlerModule/SelfLinksFilterHandlerModule";
import WarnCommandHandlerModule
  from "./modules/modules/WarnCommandHandlerModule/WarnCommandHandlerModule";
import ReputationMessageHandlerModule
  from "./modules/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModule";
import XpHandlerModule from "./modules/modules/XpHandlerModule/XpHandlerModule";
import RanksHandlerModule from "./modules/modules/RanksHandlerModule/RanksHandlerModule";
import LeaveUsersHandlerModule
  from "./modules/modules/LeaveUsersHandlerModule/LeaveUsersHandlerModule";
import SilentInviteHandlerModule
  from "./modules/modules/SilentInviteHandlerModule/SilentInviteHandlerModule";
import SilentAdminInviteHandlerModule
  from "./modules/modules/SilentAdminInviteHandlerModule/SilentAdminInviteHandlerModule";
import NewUserDeprecatedTriggersHandlerModule
  from "./modules/modules/NewUserDeprecatedTriggersHandlerModule/NewUserDeprecatedTriggersHandlerModule";
import NewUserTriggersHandlerModule
  from "./modules/modules/NewUserTriggersHandlerModule/NewUserTriggersHandlerModule";
import NewUsersHandlerModule
  from "./modules/modules/NewUsersHandlerModule/NewUsersHandlerModule";
import LocationFilterHandlerModule
  from "./modules/modules/LocationFilterHandlerModule/LocationFilterHandlerModule";
import FirstCommentHandlerModule
  from "./modules/modules/FirstCommentHandlerModule/FirstCommentHandlerModule";
import RestrictCommentHandlerModule
  from "./modules/modules/RestrictCommentHandlerModule/RestrictCommentHandlerModule";
import NetworksModerateCommandHandlerModule
  from "./modules/modules/NetworksModerateCommandHandlerModule/NetworksModerateCommandHandlerModule";
import VotebanHandlerModule from "./modules/modules/VotebanHandlerModule/VotebanHandlerModule";
import SummarizeMessageHandlerModule
  from "./modules/modules/SummarizeMessageHandlerModule/SummarizeMessageHandlerModule";
import SummarizePostHandlerModule
  from "./modules/modules/SummarizePostHandlerModule/SummarizePostHandlerModule";
import SpamReactionHandlerModule
  from "./modules/modules/SpamReactionHandlerModule/SpamReactionHandlerModule";
import ToxicFilterHandlerModule
  from "./modules/modules/ToxicFilterHandlerModule/ToxicFilterHandlerModule";
import LanguageFilterHandlerModule
  from "./modules/modules/LanguageFilterHandlerModule/LanguageFilterHandlerModule";
import KnowledgeBaseHandlerModule
  from "./modules/modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModule";
import NewUsersSafeInviteLinksModule from "./modules/modules/NewUsersSafeInviteLinksModule/NewUsersSafeInviteLinksModule";

import { ClassConstructor } from "class-transformer";

export const ModulesTypes: Array<{ name: ModuleTypesEnum, value: ClassConstructor<BaseModule> }> = [
  {
    name: ModuleTypesEnum.ReferralModule,
    value: ReferralModule
  },
  {
    name: ModuleTypesEnum.AnchorsHandlerModule,
    value: AnchorsHandlerModule
  },
  {
    name: ModuleTypesEnum.ApiNotifyHandlerModule,
    value: ApiNotifyHandlerModule
  },
  {
    name: ModuleTypesEnum.ReportCommandHandlerModule,
    value: ReportCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.ChatTopCommandHandlerModule,
    value: ChatTopCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.ModerateCommandHandlerModule,
    value: ModerateCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.CommandLimitHandlerModule,
    value: CommandLimitHandlerModule
  },
  {
    name: ModuleTypesEnum.RulesCommandHandlerModule,
    value: RulesCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.SendWakeupMessageModule,
    value: SendWakeupMessageModule
  },
  {
    name: ModuleTypesEnum.SendAdminMessageModule,
    value: SendAdminMessageModule
  },
  {
    name: ModuleTypesEnum.EntertainmentHandlerModule,
    value: EntertainmentHandlerModule
  },
  {
    name: ModuleTypesEnum.EntertainmentCallHandlerModule,
    value: EntertainmentCallHandlerModule
  },
  {
    name: ModuleTypesEnum.ChatCryptoCommandHandlerModule,
    value: ChatCryptoCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.InactiveKickHandlerModule,
    value: InactiveKickHandlerModule
  },
  {
    name: ModuleTypesEnum.FirstInactiveKickHandlerModule,
    value: FirstInactiveKickHandlerModule
  },
  {
    name: ModuleTypesEnum.DailyReportModule,
    value: DailyReportModule
  },
  /* extra moderation */
  {
    name: ModuleTypesEnum.AlbumLimitHandlerModule,
    value: AlbumLimitHandlerModule
  },
  {
    name: ModuleTypesEnum.CloseChatHandlerModule,
    value: CloseChatHandlerModule
  },
  {
    name: ModuleTypesEnum.AutocorrectionHandlerModule,
    value: AutocorrectionHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUsersAttackHandlerModule,
    value: NewUsersAttackHandlerModule
  },
  {
    name: ModuleTypesEnum.NsfwHandlerModule,
    value: NsfwHandlerModule
  },
  {
    name: ModuleTypesEnum.EditMediaHandlerModule,
    value: EditMediaHandlerModule
  },
  {
    name: ModuleTypesEnum.FacecontrolHandlerModule,
    value: FacecontrolHandlerModule
  },
  {
    name: ModuleTypesEnum.MessageBindedChannelHandlerModule,
    value: MessageBindedChannelHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUsersLimitHandlerModule,
    value: NewUsersLimitHandlerModule
  },
  {
    name: ModuleTypesEnum.BotGuardHandlerModule,
    value: BotGuardHandlerModule
  },
  {
    name: ModuleTypesEnum.EnterBindedChannelHandlerModule,
    value: EnterBindedChannelHandlerModule
  },
  // filters
  {
    name: ModuleTypesEnum.StopWordFilterHandlerModule,
    value: StopWordFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.StoryFilterHandlerModule,
    value: StoryFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.VideoNoteFilterHandlerModule,
    value: VideoNoteFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.SpoilerMessageFilterHandlerModule,
    value: SpoilerMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ContactFilterHandlerModule,
    value: ContactFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.DiceFilterHandlerModule,
    value: DiceFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.AudioFilterHandlerModule,
    value: AudioFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.HieroglyphMessageFilterHandlerModule,
    value: HieroglyphMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.RtlMessageFilterHandlerModule,
    value: RtlMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.VoiceFilterHandlerModule,
    value: VoiceFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.AnimationFilterHandlerModule,
    value: AnimationFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.VideoFilterHandlerModule,
    value: VideoFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.PhotoFilterHandlerModule,
    value: PhotoFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.DocumentFilterHandlerModule,
    value: DocumentFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.RegularExpressionFilterHandlerModule,
    value: RegularExpressionFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.PermittedWordFilterHandlerModule,
    value: PermittedWordFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.CensureFilterHandlerModule,
    value: CensureFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.CapsMessageFilterHandlerModule,
    value: CapsMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.MessageLengthFilterHandlerModule,
    value: MessageLengthFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ChannelMessageFilterHandlerModule,
    value: ChannelMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.AnyMessageFilterHandlerModule,
    value: AnyMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ChatReplyFilterHandlerModule,
    value: ChatReplyFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ChannelReplyFilterHandlerModule,
    value: ChannelReplyFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.BotReplyFilterHandlerModule,
    value: BotReplyFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.StickerFilterHandlerModule,
    value: StickerFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.FloodFilterHandlerModule,
    value: FloodFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.BotCommandFilterHandlerModule,
    value: BotCommandFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.StickerPackFilterHandlerModule,
    value: StickerPackFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.EmailLinksFilterHandlerModule,
    value: EmailLinksFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.EmojiFilterHandlerModule,
    value: EmojiFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ReferralFilterHandlerModule,
    value: ReferralFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.WebLinksFilterHandlerModule,
    value: WebLinksFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.SelfLinksFilterHandlerModule,
    value: SelfLinksFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.WarnCommandHandlerModule,
    value: WarnCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.ReputationMessageHandlerModule,
    value: ReputationMessageHandlerModule
  },
  {
    name: ModuleTypesEnum.XpHandlerModule,
    value: XpHandlerModule
  },
  {
    name: ModuleTypesEnum.RanksHandlerModule,
    value: RanksHandlerModule
  },
  {
    name: ModuleTypesEnum.LeaveUsersHandlerModule,
    value: LeaveUsersHandlerModule
  },
  {
    name: ModuleTypesEnum.SilentInviteHandlerModule,
    value: SilentInviteHandlerModule
  },
  {
    name: ModuleTypesEnum.SilentAdminInviteHandlerModule,
    value: SilentAdminInviteHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUserDeprecatedTriggersHandlerModule,
    value: NewUserDeprecatedTriggersHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUserTriggersHandlerModule,
    value: NewUserTriggersHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUsersHandlerModule,
    value: NewUsersHandlerModule
  },
  {
    name: ModuleTypesEnum.LocationFilterHandlerModule,
    value: LocationFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.FirstCommentHandlerModule,
    value: FirstCommentHandlerModule
  },
  {
    name: ModuleTypesEnum.RestrictCommentHandlerModule,
    value: RestrictCommentHandlerModule
  },
  {
    name: ModuleTypesEnum.NetworksModerateCommandHandlerModule,
    value: NetworksModerateCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.VotebanHandlerModule,
    value: VotebanHandlerModule
  },
  {
    name: ModuleTypesEnum.SummarizeMessageHandlerModule,
    value: SummarizeMessageHandlerModule
  },
  {
    name: ModuleTypesEnum.SummarizePostHandlerModule,
    value: SummarizePostHandlerModule
  },
  {
    name: ModuleTypesEnum.SpamReactionHandlerModule,
    value: SpamReactionHandlerModule
  },
  {
    name: ModuleTypesEnum.ToxicFilterHandlerModule,
    value: ToxicFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.LanguageFilterHandlerModule,
    value: LanguageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.KnowledgeBaseHandlerModule,
    value: KnowledgeBaseHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUsersSafeInviteLinksModule,
    value: NewUsersSafeInviteLinksModule
  }
]
