import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import { HandlerModeTypeEnum } from "./types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class KnowledgeBaseHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.KnowledgeBaseHandlerModule;

  @Type(() => KnowledgeBaseHandlerModuleParams)
  params!: KnowledgeBaseHandlerModuleParams;
}

class KnowledgeBaseHandlerModuleParams {
  @Type(() => KnowledgeBaseArticles)
  articles!: Array<KnowledgeBaseArticles>
  force_prefix!: Array<string>

  prefix!: string
  footer!: string
  // split_message_type None
  // thread_collector_type None

  handler_mode_type!: HandlerModeTypeEnum
}

export class KnowledgeBaseArticles {
  @Type(() => MessageEditorWithMediaData)
  text!: Array<MessageEditorWithMediaData>
}
