import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { Type } from "class-transformer";

export default class RegularExpressionFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.RegularExpressionFilterHandlerModule;

  @Type(() => RegularExpressionFilterHandlerModuleParams)
  params!: RegularExpressionFilterHandlerModuleParams
}

class RegularExpressionFilterHandlerModuleParams {
    regular_expression_strict_mode!: boolean
    regular_expressions!: Array<string>
}
