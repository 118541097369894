import { IBaseModule } from "@/includes/logic/Modules/types";
import chatState, { ChatState }     from "./chats/state";
import Config       	from "@/config";
import { postsPlannerState, PostsPlannerState } from "./postsPlanner/state";
import { FilterTypes } from "@/views/chat/filters/FilterTypes";
import networksState, { NetworksState } from "./networks/state";
import { triggersState, ITriggersState } from "./triggers/state";

import { AdminTemplateModuleStore } from "piramis-base-components/src/plugins/AdminTemplate/store/StoreTypes";

export type KeeperRuntimeConfig = {
  default_modules_config: {
    ru: Array<IBaseModule>
    en: Array<IBaseModule>
  }

  filter_templates_ru: Record<FilterTypes, any>
  filter_templates_en: Record<FilterTypes, any>

  default_config_ru: any
  default_config_en: any

  placeholders: Record<string, any>

  license_options: Record<number, Array<string>>

  langs: Array<'en' | 'ru'>

  ru_sites_top_100: Array<string>
  en_sites_top_100: Array<string>

  ai_models: Array<{
    credential_provider_name: string
    model_name: string
  }>
}

export type RootState = {
  chatState: ChatState
  triggersState: ITriggersState
  themeConfig: any
  postsPlanner: PostsPlannerState
  networksState: NetworksState
  dashboardExtendedUsers: {
    admin: false,
    value: Array<string> | Array<number>
  }
  AdminTemplate?: AdminTemplateModuleStore<KeeperRuntimeConfig>
}

const rootState: RootState = {
  // Chats state
  chatState    : chatState,
  // triggers
  triggersState,
  themeConfig  : Config,
  //posts-planner state
  postsPlanner : postsPlannerState,
  dashboardExtendedUsers: {
    admin: false,
    value: []
  },
  networksState
};

export default rootState
