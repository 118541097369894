import { FormData } from "@/includes/logic/Forms/types";

export type FormsState = {
  questionnaire: {
    forms: Array<FormData> | null
  }
}

const formsState: FormsState = {
  questionnaire: {
    forms: null
  }
}

export default formsState

