import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import FacecontrolHandlerModule from "./FacecontrolHandlerModule";

export default class FacecontrolHandlerModuleBuilder extends ModuleBuilder {
  model!: FacecontrolHandlerModule;

  view: ModuleBuilder['view'] = () => import('./FacecontrolHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: "material-icons",
    name: 'back_hand',
    iconStyle: "outlined"
  }
}

