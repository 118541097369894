import { AchievementItem } from "@/includes/types/Achievements.types";
import { RootState } from "@/store/state";

import { Commit } from "vuex";

export default {
  addAchievement({ commit }: { commit: Commit }, item: AchievementItem) {
    commit('ADD_ACHIEVEMENT', item)
  },
  updateAchievement({ commit }: { commit: Commit }, item: AchievementItem) {
    commit('UPDATE_ACHIEVEMENT', item)
  },
  setAchievements({ state, commit }: { state: RootState, commit: Commit }, id: AchievementItem['id']) {
    commit('SET_ACHIEVEMENTS', id)

    commit('updateChat', state.chatState.chat)
  }
}

