import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import { Type } from "class-transformer";

export default class NewUsersAttackHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NewUsersAttackHandlerModule;

  @Type(() => NewUsersAttackHandlerModuleParams)
  params!: NewUsersAttackHandlerModuleParams;
}

class NewUsersAttackHandlerModuleParams {
    auto_attack_mode_count!: number
    auto_attack_mode_interval!: number
    ban_inout_users!: number
    ban_inout_users_time!: number
    auto_attack_ban_permanently!: boolean
}
