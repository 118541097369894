import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import InactiveKickHandlerModule
  from "@/includes/logic/Modules/modules/modules/InactiveKickHandlerModule/InactiveKickHandlerModule";

export default class InactiveKickHandlerModuleBuilder extends ModuleBuilder {
  model!: InactiveKickHandlerModule;

  view: ModuleBuilder['view'] = () => import('./InactiveKickHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'auto_delete',
  }
}

