import { CreateTypeEnum, IModuleConfig } from '../types'
import { ConfigConditionTypes } from "../Conditions/ConfigConditionTypes";
import ConfigCondition from '../Conditions/ConfigCondition';

import { Exclude, plainToInstance, Transform } from "class-transformer";

export default class ModuleConfig implements IModuleConfig {

  @Transform(({ value }) => {
    return value.map((r: Array<any>) => {
      return r.map((b) => {
        const factory = ConfigConditionTypes.find((f) => f.name === b.type)

        if (factory) {
          return plainToInstance(factory.value, b)
        }

        return ConfigCondition
      })
    })
  })
  conditions!: Array<Array<ConfigCondition>>;

  create_type!: CreateTypeEnum;

  enabled!: boolean;

  transparent!: boolean;

  @Exclude()
  get conditionsExists() {
    return !!this.conditions.length && this.conditions.some(g => !!g.length)
  }
}

