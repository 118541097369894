import ConditionBuilder from "@/includes/logic/Modules/Conditions/ConditionBuilder";
import BoostsCondition from "./BoostsCondition";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

class BoostsConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Boosts;

  icon: string = 'volunteer_activism';
  view: VueComponent = () => import('./BoostsConditionView.vue');
  preview: VueComponent = () => import('./BoostsConditionValue.vue');

  getModel(): ConfigCondition {
    return new BoostsCondition()
  }
}

export default new BoostsConditionBuilder()
