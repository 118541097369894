import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import VideoFilterHandlerModule from "./VideoFilterHandlerModule";

export default class VideoFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: VideoFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "videocam",
    color: "rgba(var(--a-primary), 1)"
  };
  view = () => import('@/includes/logic/Modules/modules/modules/VideoFilterHandlerModule/VideoFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', ]

}
