import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import { ActiveChatTriggers } from "@/includes/types/triggers";

export type ITriggersState = {
  /*all the triggers*/
  triggers: ActiveChatTriggers | null
  /*chat triggers*/
  // frontendTriggers: Array<RawTriggerData> | null
  newTrigger: RawTriggerData | null
  loadingTriggerPromise: Promise<void> | null
  updatedTriggerId: string | null
}

export const triggersState: ITriggersState = {
  triggers: null,
  // frontendTriggers: null,
  newTrigger: null,
  loadingTriggerPromise: null,
  updatedTriggerId: null
}
