import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class LanguageFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.LanguageFilterHandlerModule;

  @Type(() => LanguageFilterHandlerModuleParams)
  params!: LanguageFilterHandlerModuleParams
}

class LanguageFilterHandlerModuleParams {
  languages!: BlackWhiteListItem<string>
  percent!: number
}
