import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import SummarizeMessageHandlerModule from "./SummarizeMessageHandlerModule";
import store from "@/store/store";
import { ADVANCED_TAG, AI_EXPERIMENTAL_TAG } from "@/includes/constants";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class SummarizeMessageHandlerModuleBuilder extends ModuleBuilder {
  model!: SummarizeMessageHandlerModule;

  view: ModuleBuilder['view'] = () => import('./SummarizeMessageHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.AI

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'filter',
  }

  tags(): Array<TagData> | null {
    return AI_EXPERIMENTAL_TAG
  }

  tariffTags(): Array<TagData> | null {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null;
  }
}

