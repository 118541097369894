import { ModuleConditionTypeEnum, ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import SendWakeupMessageModule
  from "@/includes/logic/Modules/modules/modules/SendWakeupMessageModule/SendWakeupMessageModule";

export default class SendWakeupMessageModuleBuilder extends ModuleBuilder {
  model!: SendWakeupMessageModule;

  view: ModuleBuilder['view'] = () => import('./SendWakeupMessageModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'auto_graph',
  }

  aliases = [ 'повышение активности', 'активность чата', 'активность', ]

  conditionsTypes: Array<ModuleConditionTypeEnum> = [
      ModuleConditionTypeEnum.WorkTime
  ]
}

