import { AchievementItem } from "@/includes/types/Achievements.types";
import { RootState } from "@/store/state";

export default {
  ADD_ACHIEVEMENT(state:RootState, item: AchievementItem) {
    if (state.chatState.chat) {
      state.chatState.chat.common_config.achievements.push(item)
    }
  },
  UPDATE_ACHIEVEMENT(state:RootState, item: AchievementItem) {
    if (state.chatState.chat) {
      const idx = state.chatState.chat.common_config.achievements.findIndex(a => a.id === item.id)

      if (idx !== -1) {
        state.chatState.chat.common_config.achievements.splice(idx, 1, item)
      }
    }
  },
  SET_ACHIEVEMENTS(state:RootState, achievements: Array<AchievementItem>) {
    if (state.chatState.chat) {
      state.chatState.chat.common_config.achievements = achievements

    }
  },
};
