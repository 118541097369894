import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import LanguageFilterHandlerModule from "./LanguageFilterHandlerModule";
import { ADVANCED_TAG, AI_EXPERIMENTAL_TAG } from "@/includes/constants";
import store from "@/store/store";
import BaseModule from "@/includes/logic/Modules/modules/BaseModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class LanguageFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: LanguageFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.AI

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "flag",
    color: "rgba(var(--a-dark), 1)"
  };

  setBuilderModel(moduleData: BaseModule) {
    super.setBuilderModel(moduleData);

    this.model.params.percent = 1;
  }

  tariffTags(): Array<TagData> | null {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
  }

  tags(): Array<TagData> | null {
    return AI_EXPERIMENTAL_TAG
  }

  view = () => import('@/includes/logic/Modules/modules/modules/LanguageFilterHandlerModule/LanguageFilterHandlerModule.vue')
}
