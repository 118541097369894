import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import ConfigCondition from "../../ConfigCondition";

export default class WorkTimeCondition extends ConfigCondition {
  type = ModuleConditionTypeEnum.WorkTime;

  to = ''

  from = ''
}
