import ResolveFromRankCondition from "./conditions/ResolveFromRankCondition/ResolveFromRankCondition";
import ResolveFromRepCondition from "./conditions/ResolveFromRepCondition/ResolveFromRepCondition";
import TopicsCondition from "./conditions/TopicsCondition/TopicsCondition";
import UsersCondition from "./conditions/UsersCondition/UsersCondition";
import ResolveFromXpCondition from "./conditions/ResolveFromXpCondition/ResolveFromXpCondition";
import ResolveFromApCondition from "./conditions/ResolveFromApCondition/ResolveFromApCondition";
import ZoneCondition from "./conditions/ZoneCondition/ZoneCondition";
import WorkTimeCondition from "./conditions/WorkTimeCondition/WorkTimeCondition";
import BoostsCondition from "./conditions/BoostsCondition/BoostsCondition";
import UserTypeCondition from "./conditions/UserType/UserTypeCondition";
import MessageContentTypeCondition from "./conditions/MessageContentTypeCondition/MessageContentTypeCondition";
import HasAchievementModuleConfig from "./conditions/HasAchievementModuleConfig/HasAchievementModuleConfig";
import ConfigCondition from "./ConfigCondition";
import { ModuleConditionTypeEnum } from "../types";

import { ClassConstructor } from "class-transformer";

export const ConfigConditionTypes: Array<{ name: ModuleConditionTypeEnum, value: ClassConstructor<ConfigCondition> }> = [
  {
    name: ModuleConditionTypeEnum.Topics,
    value: TopicsCondition,
  },
  {
    name: ModuleConditionTypeEnum.Zone,
    value: ZoneCondition,
  },
  {
    name: ModuleConditionTypeEnum.Users,
    value: UsersCondition,
  },
  {
    name: ModuleConditionTypeEnum.WorkTime,
    value: WorkTimeCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromRank,
    value: ResolveFromRankCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromXp,
    value: ResolveFromXpCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromRep,
    value: ResolveFromRepCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromAp,
    value: ResolveFromApCondition,
  },
  {
    name: ModuleConditionTypeEnum.Boosts,
    value: BoostsCondition,
  },
  {
    name: ModuleConditionTypeEnum.UserType,
    value: UserTypeCondition,
  },
  {
    name: ModuleConditionTypeEnum.MessageContentType,
    value: MessageContentTypeCondition,
  },
  {
    name: ModuleConditionTypeEnum.HasAchievementModuleConfig,
    value: HasAchievementModuleConfig,
  },
]
