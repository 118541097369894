import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";
import ConfigCondition from "../../ConfigCondition";
import { BlackWhiteListItem, BlackWhiteListItemType } from "piramis-base-components/src/components/BlackWhiteList/types";

export default class UsersCondition extends ConfigCondition {
  type = ModuleConditionTypeEnum.Users;

  values: BlackWhiteListItem<number> = {
    type: BlackWhiteListItemType.Blacklist,
    items: []
  };
}
