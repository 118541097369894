import { BasePunishActionBuilder } from "@/includes/logic/Modules/PunishActions/PunishActionBuilder";
import { DeleteMessageAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

export class DeleteMessageActionBuilder extends BasePunishActionBuilder<DeleteMessageAction> {
  type = PunishActionTypesEnum.DeleteMessage

  icon = 'comments_disabled'

  view = () => import('./DeleteMessageActionView.vue');

  getModel(): DeleteMessageAction {
    return plainToInstance(DeleteMessageAction, {
      type: PunishActionTypesEnum.DeleteMessage,
      delete_message_delay: 0,
      message: []
    })
  }
}

export const DeleteMessageActionBuilderInstance= new DeleteMessageActionBuilder()
