import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import { Type } from "class-transformer";
import i18n from "@/i18n/i18n";

export default class EntertainmentHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.EntertainmentHandlerModule;

  @Type(() => EntertainmentHandlerModuleParams)
  params!: EntertainmentHandlerModuleParams;
}

class EntertainmentHandlerModuleParams {
  entertainment_ban_type!: "Kick" | "None" | "Ban" | "Mute"
  //todo
  entertainment_action!: Array<any>
}
