import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"

import ApiNotifyHandlerModule from "./ApiNotifyHandlerModule";

export default class ApiNotifyHandlerModuleBuilder extends ModuleBuilder {
  model!: ApiNotifyHandlerModule;

  view: ModuleBuilder['view'] = () => import('@/includes/logic/Modules/modules/modules/ApiNotifyHandlerModule/ApiNotifyHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'extension',
  }

  hasLimits(): boolean {
    return this.checkLimitOption('integrations') !== null
  }
}

