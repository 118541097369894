import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import { AdminOrAllPermissions } from "@/includes/types/Enums";

import { Type } from "class-transformer";

export default class EntertainmentCallHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.EntertainmentCallHandlerModule;

  @Type(() => EntertainmentCallHandlerModuleParams)
  params!: EntertainmentCallHandlerModuleParams;
}

class EntertainmentCallHandlerModuleParams {
  entertainment_call_enabled!: AdminOrAllPermissions
}
