import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";

import { Type } from "class-transformer";

export default class DailyReportModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.DailyReportModule;

  @Type(() => DailyReportModuleParams)
  params!: DailyReportModuleParams;
}

class DailyReportModuleParams {
  report_users!: Record<number, string>
}
