import ConditionBuilder from "@/includes/logic/Modules/Conditions/ConditionBuilder";
import UserTypeCondition from "./UserTypeCondition";
import ConfigCondition from "../../ConfigCondition";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";
import { ModuleConditionTypeEnum } from "../../../types";

class UserTypeConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.UserType;

  icon: string = 'workspace_premium';

  view: VueComponent = () => import('./UserTypeConditionView.vue');
  preview: VueComponent = () => import('./UserTypeConditionValue.vue');

  getModel(): ConfigCondition {
    return new UserTypeCondition();
  }
}

export default new UserTypeConditionBuilder()
