import ConditionBuilder from "../../ConditionBuilder";
import MessageContentTypeCondition from "./MessageContentTypeCondition";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types";

class MessageContentTypeConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.MessageContentType;

  icon = 'message';

  view:ConditionBuilder['view'] = () => import('./MessageContentTypeConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('./MessageContentTypeConditionValue.vue');

  getModel(): ConfigCondition {
    return new MessageContentTypeCondition()
  }
}

export default new MessageContentTypeConditionBuilder()
