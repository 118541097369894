import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { ModuleGroupEnum, ModuleTypesEnum } from "@/includes/logic/Modules/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class ReportCommandHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ReportCommandHandlerModule;

  @Type(() => ReportCommandHandlerModuleParams)
  params!: ReportCommandHandlerModuleParams;
}

class ReportCommandHandlerModuleParams {
  report_command_to_all!: boolean
  report_remove!: boolean

  @Type(() => MessageEditorWithMediaData)
  report_action_message!: Array<MessageEditorWithMediaData>

  @Type(() => MessageEditorWithMediaData)
  report_info_message!: Array<MessageEditorWithMediaData>

  report_count_to_delete_message!: number
  journal_enabled!: boolean
  report_users!: Record<number, string>
  report_to_admins!: boolean
}
