import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class WebLinksFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.WebLinksFilterHandlerModule;

  @Type(() => WebLinksFilterHandlerModuleParams)
  params!: WebLinksFilterHandlerModuleParams
}

class WebLinksFilterHandlerModuleParams {
    web_links_strict_mode!: boolean
    accept_http_links_whitelist!: BlackWhiteListItem<string>
    accept_http_links_from_top_100!: boolean
    accept_http_links_from_top_ru_100!: boolean
    web_links_dont_check_ips!: boolean
    search_links_in_qr_codes!: boolean
}
