import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import AnchorsHandlerModule from "./AnchorsHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class AnchorsHandlerModuleBuilder extends ModuleBuilder {
  model!: AnchorsHandlerModule;

  view: ModuleBuilder['view'] = () => import('./AnchorsHandlerModuleView.vue')

  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'question_answer',
  }

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('anchors')
  }
}

