import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import { getNetworkTriggers } from "@/includes/logic/Networks/logic";
import { ITriggersState } from "@/store/triggers/state";

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { SelectOption } from "piramis-base-components/src/logic/types";

import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class TriggersMixin extends Vue {

  get currentNetwork() {
    return this.$store.state.networksState.currentNetwork
  }

  get chatTriggers(): ITriggersState['triggers'] {
    return this.$store.state.triggersState.triggers
  }

  get taggedTriggers(): Array<SelectOptionData> {
    const activeTriggerOptions = (this.chatTriggers?.active ?? []).map<SelectOptionData>((trigger) => {
      return {
        label: trigger.name,
        value: trigger.id,
        tags: [
          {
            text: this.$t("option_trigger_active").toString(),
            color: "rgba(var(--a-success), 1)",
          },
        ],
      };
    })

    const inactiveTriggerOptions = (this.chatTriggers?.disabled ?? []).map<SelectOptionData>((trigger) => {
      return {
        label: trigger.name,
        value: trigger.id,
        tags: [
          {
            text: this.$t("option_trigger_disabled").toString(),
            color: "rgba(var(--a-danger), 1)",
          },
        ],
      };
    });

    return [
      ...activeTriggerOptions,
      ...inactiveTriggerOptions,
    ];
  }

  async getTaggedTriggerOptions() {
    if (this.$store.getters.isChatSet && !this.chatTriggers) {
      await this.$store.dispatch('updateTriggers')
    } else if (this.currentNetwork && !this.$store.state.networksState.triggers.triggers) {
      await getNetworkTriggers(this.currentNetwork.id)
    }

    return this.taggedTriggers
  }

  get getActiveTriggersIds(): Array<string | number> {
    if (this.$store.getters.isChatSet) {
      return this.$store.state.chatState.chat?.triggers ?? []
    } else if (this.currentNetwork) {
      return this.currentNetwork.triggers
    } else {
      return []
    }
  }

  get activeTriggersOptions() {
    if (this.$store.getters.isChatSet) {
      return this.mapTriggerOptions(this.chatTriggers?.active ?? []);
    } else if (this.currentNetwork) {
      return this.mapTriggerOptions(this.$store.state.networksState.triggers.triggers ?? [])
    }

    return []
  }

  async resolveTriggersOptions() {
    if (this.$store.getters.isChatSet) {
      if (!this.chatTriggers) {
        await this.$store.dispatch('updateTriggers')
      }

      return this.activeTriggersOptions;
    } else if (this.currentNetwork) {
      const { networksState } = this.$store.state

      if (networksState.triggers.triggers === null) {
        await getNetworkTriggers(networksState.currentNetwork.id)
      }

      return this.mapTriggerOptions(networksState.triggers.triggers)
    }

    return []
  }

  mapTriggerOptions(triggers: Array<RawTriggerData>): Array<SelectOption> {
    return triggers
        .map((t) => ({
          label: t.name,
          value: t.id
        }))
  }
}
