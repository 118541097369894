import ConditionBuilder from "../../ConditionBuilder";
import ResolveFromXpCondition from "./ResolveFromXpCondition";
import ConfigCondition from "../../ConfigCondition";
import { ModuleConditionTypeEnum } from "../../../types";

class ResolveFromXpConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromXp;

  icon = 'exposure';

  view:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('../ResolveFromCondition/ResolveFromConditionValue.vue');

  getModel(): ConfigCondition {
    return new ResolveFromXpCondition();
  }

}

export default new ResolveFromXpConditionBuilder()
