import i18n from "@/i18n/i18n";
import Store from "@/store/store";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import TriggersApi from "@/includes/Api/Triggers.api";
import { getTriggerTemplate } from "@/components/TriggerSetup/logic/trigger/triggerTemplates";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import { ActiveChatTriggers, EditChatTriggersReq } from "@/includes/types/triggers";

import { AxiosResponse } from "axios";
import Vue from "vue";
import { cloneDeep } from "lodash";

export default class VuTriggers {
  static getTriggers<T extends boolean>(active?: boolean) {
    if (Store.state.chatState.chat) {
      return TriggersApi.getChatTriggers<T>(Store.state.chatState.chat!.chat_id, active)
          .then(res => res.data)
          .catch(errorNotification)
    } else {
      return Promise.resolve()
    }
  }

  static setTrigger(trigger: RawTriggerData) {
    return new Promise<AxiosResponse<RawTriggerData>>((resolve, reject) => {
      TriggersApi.setTrigger(trigger)
          .then(resolve)
          .catch(reject)
    });
  }

  static deleteTrigger(tIds: EditChatTriggersReq['triggers']) {
    const { triggers } = Store.state.triggersState

    if (triggers?.active) {
      return this.editChatTriggers({
        triggers: tIds,
        action: 'Delete'
      })
    } else {
      return Promise.resolve()
    }
  }

  static updateTriggers() {
    return new Promise<void>((resolve, reject) => {
      const triggers = this.getTriggersFromStore();

      if (!triggers) {
        Store.dispatch("updateTriggers")
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
      } else {
        resolve();
      }
    });
  }

  static getTriggersFromStore() {
    return Store.state.triggersState.triggers;
  }

  static deleteFrontendTrigger(id: string) {
    return new Promise<void | ActiveChatTriggers>((resolve, reject) => {
      Vue.prototype.$confirm({
        title: i18n.t("trigger_confirm_remove").toString(),
        content: i18n.t("trigger_confirm_remove_text").toString(),
        okText: i18n.t("accept").toString(),
        cancelText: i18n.t("reject").toString(),
        onOk: () => {
          this.deleteTrigger([ id ])
              .then(resolve)
        },
      })
    });
  }

  static setTriggerFrontEnd(data: RawTriggerData, isCopy = false) {
    let triggerTemplate = data;

    triggerTemplate = Object.assign(this.getTriggerTemplate(), triggerTemplate);

    Store.commit("setNewTrigger", triggerTemplate);
  }

  static getTriggerTemplate(): RawTriggerData {
    return getTriggerTemplate()
  }

  static getTriggerFormVuex(id: string) {
    const triggers = (Store.state.triggersState.triggers?.active ?? []).concat(Store.state.triggersState.triggers?.disabled ?? []);
    const neededIndex = triggers.findIndex(t => t.id === id);

    return triggers[neededIndex];
  }

  static copyTrigger(id: string) {
    const neededItem = cloneDeep(this.getTriggerFormVuex(id));

    delete neededItem.id;
    delete neededItem.owner_id;

    this.setTriggerFrontEnd(neededItem, true);
  }

  private static editChatTriggers({ triggers, action }: Omit<EditChatTriggersReq, 'chat_id'>) {
    const chatId = Store.getters.chatId

    return new Promise<ActiveChatTriggers | void>(resolve => {
      TriggersApi.editChatTriggers({
        chat_id: chatId,
        triggers,
        action
      })
          .then(({ data }) => {
            successNotification()

            Store.commit('UPDATE_TRIGGERS', data)

            resolve(data)
          })
          .catch((error) => {
            errorNotification(error)

            resolve()
          })
          .then(() => {
            Store.dispatch('requestChatConfig', { id: chatId })
          })
    })
  }

  static restoreTrigger(triggers: EditChatTriggersReq['triggers']) {
    return this.editChatTriggers({
      triggers,
      action: "Restore"
    })
  }

  static setTriggers(triggers: EditChatTriggersReq['triggers']) {
    return this.editChatTriggers({
      triggers,
      action: 'Set'
    })
  }

  static activateTrigger(id: string) {
    const { chat } = Store.state.chatState

    if (chat) {
      const activeTriggerIds = chat.triggers.slice()
      activeTriggerIds.push(id)

      return this.setTriggers(activeTriggerIds)
    } else {
      return Promise.resolve()
    }
  }

  static disableTrigger(id: string) {
    const { chat } = Store.state.chatState

    if (chat) {
      const activeTriggerIds = chat.triggers

      return this.setTriggers(activeTriggerIds.filter(tid => tid !== id))
    } else {
      return Promise.resolve()
    }
  }

  static getChatTriggersStat() {
    return TriggersApi.getChatTriggersStat(Store.getters.chatId)
        .then(({ data }) => data)
        .catch((error) => {
          errorNotification(error)
        })
  }
}
