import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import NewUsersSafeInviteLinksModule from "./NewUsersSafeInviteLinksModule";

export default class NewUsersSafeInviteLinksModuleBuilder extends ModuleBuilder {
  model!: NewUsersSafeInviteLinksModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration

  view =  () => import('./NewUsersSafeInviteLinksModuleView.vue')

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'add_link',
    iconStyle: 'outlined'
  }
}

