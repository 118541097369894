import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { Type } from "class-transformer";

export default class SpoilerMessageFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SpoilerMessageFilterHandlerModule;

  @Type(() => SpoilerMessageFilterHandlerModuleParams)
  params!: SpoilerMessageFilterHandlerModuleParams;
}

class SpoilerMessageFilterHandlerModuleParams {
  spoiler_min_message_len!: number
  spoiler_min_words_count!: number
  spoiler_max_size!: number
  spoiler_allow_message_begin!: boolean
  spoiler_allow_message_end!: boolean
}
